import {Container, Stack, Title} from "@mantine/core"
import {useEthers} from "@usedapp/core"
import React from "react"
import {useLocation, useNavigate} from "react-router-dom"
import AccountHeader from "../components/account/AccountHeader"
import ReferralLink from "../components/common/ReferralLink"

export default function SaleSuccess() {
  const {account} = useEthers()
  const navigate = useNavigate()
  const location = useLocation()
  const txHash = location.state?.txHash ?? ""
  const estimatedLosses = location.state?.estimatedLosses ?? null

  if (!account || !txHash) {
    return navigate("/")
  }

  return (
    <Container py="xl">
      <Stack align="center">
        <AccountHeader />
        <Title mt="xl">🥳 Congrats on the successful sale!</Title>
        <Title order={3}>🤝 Pleasure doing business with you.</Title>
      </Stack>
      <ReferralLink losses={estimatedLosses} />
    </Container>
  )
}
