import React, {useCallback, useEffect, useState} from "react"
import {ethers} from "ethers"
import randomstring from "random-string"
import {JUNKYARD_ADDRESS} from "../../constants"
import junkyardAbi from "../../abis/Junkyard.json"
import {useEthers} from "@usedapp/core"
import {
  Button,
  Card,
  Loader,
  Stack,
  Text,
  Flex,
  TextInput,
  CopyButton,
  Group,
  Title
} from "@mantine/core"
import {IconClipboardCheck, IconClipboardCopy} from "@tabler/icons"
import Web3ModalButton from "../account/Web3ModalButton"
import Tweet from "./Tweet"

const ReferralLink = ({losses}) => {
  const {library, account} = useEthers()
  const [link, setLink] = useState("")
  const [fetchLinkLoading, setFetchLinkLoading] = useState(false)
  const [fetchLinkError, setFetchLinkError] = useState(false)
  const [createLinkLoading, setCreateLinkLoading] = useState(false)
  const [createLinkError, setCreateLinkError] = useState(false)

  const checkExistingLink = useCallback(async () => {
    setFetchLinkError(false)
    setFetchLinkLoading(true)
    try {
      const junkyardContract = new ethers.Contract(
        JUNKYARD_ADDRESS,
        junkyardAbi,
        library.getSigner()
      )
      const _link = await junkyardContract.referralCodeByUser(account)
      setLink(_link)
    } catch (e) {
      console.error(e)
      setFetchLinkError(true)
    }
    setFetchLinkLoading(false)
  }, [account, library])
  useEffect(() => {
    if (library && account) {
      checkExistingLink()
    }
  }, [library, account, checkExistingLink])

  const createReferralLink = async () => {
    setCreateLinkError(false)
    setCreateLinkLoading(true)
    try {
      const junkyardContract = new ethers.Contract(
        JUNKYARD_ADDRESS,
        junkyardAbi,
        library.getSigner()
      )
      const code = randomstring({length: 16})
      await junkyardContract.setReferralCode(code)
      setLink(code)
      return code
    } catch (e) {
      console.error(e)
      setCreateLinkError(true)
    }
    setCreateLinkLoading(false)
  }

  return (
    <>
      <Group grow mt="xl">
        <Tweet losses={losses} refCode={link} onCreateRefLink={createReferralLink} />
        <Card radius="lg" p="xl" sx={{backgroundColor: "white", flex: "1", alignSelf: "stretch"}}>
          <Stack>
            <img
              height="40px"
              src="dollar.png"
              alt="dollar_icon"
              style={{alignSelf: "flex-end", marginRight: 15}}
            />
            <Text size="xl">Earn by sharing your referral link!</Text>
            <Card.Section inheritPadding>
              {library && account ? (
                fetchLinkLoading ? (
                  <Loader />
                ) : link ? (
                  <Flex align="center">
                    <TextInput
                      disabled
                      value={`https://nfttaxsaver.xyz?ref=${link}`}
                      style={{width: "330px"}}
                    />
                    <CopyButton value={`https://nfttaxsaver.xyz?ref=${link}`}>
                      {({copied, copy}) => (
                        <Button color={copied ? "teal" : "blue"} onClick={copy}>
                          {copied ? <IconClipboardCheck /> : <IconClipboardCopy />}
                        </Button>
                      )}
                    </CopyButton>
                  </Flex>
                ) : fetchLinkError ? (
                  <Text color="indianred">Error fetching referral link</Text>
                ) : (
                  <Flex align="center">
                    <Button
                      onClick={createReferralLink}
                      target="_blank"
                      variant="outline"
                      radius="xl"
                      sx={{borderWidth: "2px", marginRight: "10px"}}
                      loading={createLinkLoading}
                    >
                      {createLinkError ? "Retry" : "Create referral link"}
                    </Button>
                    {createLinkError && <Text color="indianred">Error creating link</Text>}
                  </Flex>
                )
              ) : (
                <>
                  <Web3ModalButton text="Connect Wallet" size="md" />
                </>
              )}
            </Card.Section>
          </Stack>
        </Card>
      </Group>
      <Title size="h2" sx={{marginTop: "40px"}}>
        {" "}
        How Our Referral Program Works{" "}
      </Title>
      <Text>Our referral program is a simple way to earn during the bear market.</Text>
      <br />
      <Text>
        1. Start by generating your own on-chain referral code above. It’s tied to your wallet
        address.
      </Text>
      <Text>
        2. Share your link with friends and followers who are looking to tax loss harvest their
        NFTs.
      </Text>
      <Text>3. You’ll earn $1 for every NFT they sell using your link!</Text>
      <br />
      <Text>
        Our team will tally up the earnings and make them available to you at some point between the
        1st and the 7th of the following month – all on-chain.
      </Text>
      <br />
      <Text>Note: you must have at least $10 in unpaid earnings to be eligible for payout.</Text>
    </>
  )
}

export default ReferralLink
