import {Badge, Button, Card, CloseButton, NumberInput, Overlay, Stack, Text} from "@mantine/core"
import {useHover} from "@mantine/hooks"
import {IconCircleCheck} from "@tabler/icons"
import React, {useState} from "react"
import {getContractLabel, getMediaComponent, getNFTKey} from "../../utils/nftUtils"

export default function NFTCard({nftDatum, selectedNFTs, setSelectedNFTs}) {
  const {hovered, ref} = useHover()
  const [erc1155Selected, setERC1155Selected] = useState(false)

  const isSelected = getNFTKey(nftDatum) in selectedNFTs

  const balance = nftDatum["balance"]
  const [balanceToJunk, setBalanceToJunk] = useState(
    isSelected ? selectedNFTs[getNFTKey(nftDatum)][1] : balance
  )

  const isERC1155 = nftDatum["tokenType"] === "ERC1155"

  const cardClicked = junkAll => {
    if (isSelected) {
      const {[getNFTKey(nftDatum)]: _, ...rest} = selectedNFTs
      setSelectedNFTs(rest)
    } else {
      setSelectedNFTs({
        ...selectedNFTs,
        [getNFTKey(nftDatum)]: [nftDatum, junkAll ? balance : balanceToJunk]
      })
      setERC1155Selected(false)
    }
  }

  const erc1155CardClicked = () => {
    setERC1155Selected(true)
  }

  const handleJunkAll = () => {
    setBalanceToJunk(balance)
    cardClicked(true)
  }

  const topRightIcon = isSelected ? (
    <IconCircleCheck
      style={{position: "absolute", top: 3, right: 3, zIndex: 1}}
      size={40}
      strokeWidth={2}
      color={"black"}
    />
  ) : hovered ? (
    <IconCircleCheck
      style={{position: "absolute", top: 3, right: 3, zIndex: 1}}
      size={40}
      strokeWidth={2}
      color={"gray"}
    />
  ) : null

  const topLeftCount = isSelected ? (
    <Badge sx={{position: "absolute", zIndex: 1, left: 3, top: 3}} color="dark" radius="xs">
      {balanceToJunk} / {balance}
    </Badge>
  ) : (
    <Badge
      sx={{
        position: "absolute",
        zIndex: 1,
        left: 3,
        top: 3,
        textTransform: "lowercase"
      }}
      color="dark"
      radius="xs"
    >
      x{balance}
    </Badge>
  )

  const media = getMediaComponent(nftDatum, 140)

  const card = (
    <Card
      ref={ref}
      shadow="sm"
      p="lg"
      radius="md"
      withBorder
      onClick={
        isERC1155 && balance > 1 && !isSelected
          ? erc1155CardClicked
          : () => {
              cardClicked()
            }
      }
      sx={hovered ? {cursor: "pointer", height: "260px"} : {height: "260px"}}
    >
      {erc1155Selected && <Overlay opacity={0.6} color="#000" blur={5} sx={{zIndex: 3}} />}
      <div style={{marginTop: "-20px"}}>
        {isERC1155 ? topLeftCount : undefined}
        {topRightIcon}
        {media}
      </div>

      <Text size="xs" mt="lg" weight={500}>
        {nftDatum.title}
      </Text>
      <Text size="xs" mt="lg">
        {getContractLabel(nftDatum)}
      </Text>
    </Card>
  )

  return (
    <>
      {erc1155Selected && (
        <>
          <CloseButton
            sx={{position: "absolute", zIndex: 6}}
            variant="transparent"
            color="gray.0"
            onClick={() => setERC1155Selected(false)}
          />
          <Stack sx={{position: "absolute", zIndex: 5, width: 140}} pt="xl" px="sm" align="center">
            <Text color="white" size="xs" weight={500}>
              How many to junk?
            </Text>
            <Button fullWidth variant="light" onClick={handleJunkAll}>
              <Text size="xs">Junk All ({balance})</Text>
            </Button>
            <NumberInput
              mt="xs"
              mb="-xs"
              value={balanceToJunk}
              onChange={val => setBalanceToJunk(val)}
              max={balance}
              min={1}
            />
            <Button
              size="xs"
              fullWidth
              variant="light"
              onClick={() => {
                cardClicked()
              }}
            >
              Junk
            </Button>
          </Stack>
        </>
      )}
      {card}
    </>
  )
}
