import {Network} from "alchemy-sdk"

const ETHERSCAN_URLS = {
  ETH_MAINNET: "https://etherscan.io",
  ETH_GOERLI: "https://goerli.etherscan.io"
}

export const JUNKYARD_ADDRESS = process.env.REACT_APP_JUNKYARD_ADDRESS
export const ETHERSCAN_URL = ETHERSCAN_URLS[process.env.REACT_APP_NETWORK]
const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY

const NETWORK = Network[process.env.REACT_APP_NETWORK]
export const ALCHEMY_API_URL = `https://${NETWORK}.alchemyapi.io/v2/${ALCHEMY_API_KEY}`
export const SETTINGS = {
  apiKey: ALCHEMY_API_KEY,
  network: NETWORK
}

const CHAIN_IDS = {
  ETH_MAINNET: 1,
  ETH_GOERLI: 5
}
export const CHAIN_ID = CHAIN_IDS[NETWORK]

export const INFURA_ID = process.env.REACT_APP_INFURA_ID

export const BLOG_TITLE_DO_I_NEED_TO_PAY = "Do I need to pay taxes on NFTs? - 2022 Guide"
export const BLOG_TITLE_HOW_TO_HARVEST = "How to Tax Loss Harvest NFTs - Complete Guide 2022"
export const BLOG_TITLE_HOW_ARE_NFTS_TAXED = "How are NFTs taxed? - 2022 NFT Taxes Explained"
