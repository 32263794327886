import React from "react"
import {Helmet} from "react-helmet"
import {Container, Stack, Title, Text} from "@mantine/core"

export default function FAQ() {
  return (
    <>
      <Helmet>
        <title>FAQ | NFT Tax Saver</title>
      </Helmet>
      <Container>
        <Stack>
          <Title mb="lg">FAQ</Title>
          <Text weight={700}>What is tax loss harvesting?</Text>
          <Text>
            Tax loss harvesting is a technique used by professional investors, wealth managers, and
            trading firms to offset their profits by locking in losses on assets that are currently
            worth less than what they originally paid. Because your tax bill is calculated based on
            how much money you’ve made, you can lower your tax bill by realizing losses (basically,
            selling NFTs that you know are currently worth less than what you paid). With liquid
            assets like stocks, locking in these losses is easy – just hit the red button and sell.
            But because NFTs are highly illiquid (there’s often nobody to buy your NFT after the
            hype dies down), it’s difficult or impossible to formally realize losses. If you don’t
            sell, you don’t realize losses, and you’re stuck with a higher tax bill.
          </Text>
          <Text weight={700} mt="lg">
            Is tax loss harvesting legal?
          </Text>
          <Text>
            Tax loss harvesting is a very common practice. Here are some examples of reputable
            organizations discussing the merits of tax loss harvesting:{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.privatebank.bankofamerica.com/articles/tax-loss-harvesting.html"
            >
              Bank of America
            </a>
            ,{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.fidelity.com/viewpoints/personal-finance/tax-loss-harvesting"
            >
              Fidelity
            </a>
            ,{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.chase.com/personal/investments/learning-and-insights/article/why-you-may-benefit-from-tax-loss-harvesting"
            >
              Chase
            </a>
            . Wealth management companies like{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.betterment.com/tax-loss-harvesting"
            >
              Betterment
            </a>{" "}
            even promote Tax Loss Harvesting as one of their features for consumers. As always,
            check with your accountant if you are unsure.
          </Text>
          <Text weight={700} mt="lg">
            If I know my NFT isn’t worth anything, do I have to literally sell my NFT to realize the
            loss?
          </Text>
          <Text>
            Yes, because profits or losses are only realized once a sell transaction occurs. If no
            transaction occurs, you are technically sitting on an “unrealized” gain or loss.
            Unrealized profits or losses in the US do not count towards your year-end tax bill. So,
            if you want to officially decrease your tax bill using an NFT you know is in loss, you
            need to lock in that loss by actually selling it. Again, this can be difficult, because
            NFTs in loss often have no buyers at any given moment. NFT Tax Saver helps provide
            liquidity for any NFT you want to get rid of by making it possible to sell any NFT to
            us.
          </Text>
          <Text weight={700} mt="lg">
            Why do I have to approve so many wallet transactions before I sell?
          </Text>
          <Text>
            To allow NFT Tax Saver to buy your NFT, you need to give it permission to interact with
            your NFTs first. This approval is done at a contract level, which means you need to
            approve a separate transaction for each NFT collection. So if you are trying to sell 2
            NFTs from the same collection, that would only require 1 approval transaction. But if
            you are trying to sell 1 NFT from Collection A and 1 NFT from Collection B, that will
            require 2 separate approval transactions.
          </Text>
          <Text>
            After all necessary approval transactions are complete, there is 1 final transaction
            which confirms the sale itself.{" "}
          </Text>
          <Text weight={700} mt="lg">
            Is there any way to decrease gas?
          </Text>
          <Text>
            We’ve already done a ton of optimization work engineering-wise to decrease the gas costs
            as much as possible. When we buy your NFTs, we are ultimately invoking the “Transfer”
            function contained within the contract of each NFT you sell. Therefore, we are at the
            mercy of the gas costs associated with the “Transfer” functions of all your NFT
            collections, combined. There is nothing we can do about this.
          </Text>
          <Text>
            One silver lining, however – many accountants say it is ok to consider gas costs as part
            of your cost-basis, which means your gas costs spent on transactions can actually
            decrease your tax bill too.
          </Text>
          <Text weight={700} mt="lg">
            What do you do with the NFTs once you’ve purchased them from me?
          </Text>
          <Text>
            One day, we may try to see if some of them have value, which will be a fun and
            interesting problem to tackle. We’ll keep you posted.
          </Text>
          <Text weight={700} mt="lg">
            I love what you are doing. Can I meet the team or invest?
          </Text>
          <Text>
            We’re not accepting further investment at the moment. But feel free to drop us a note at
            team@nfttaxsaver.xyz.
          </Text>
          <Text weight={700} mt="lg">
            Wen token?
          </Text>
          <Text>:)</Text>
        </Stack>
      </Container>
    </>
  )
}
