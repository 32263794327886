import React from "react"
import {Avatar, Group, Stack, Title, Text} from "@mantine/core"

export default function BlogHeading({title, author, date}) {
  return (
    <Stack spacing="xs" mb="lg">
      <Title>{title}</Title>
      <Group>
        <Avatar size="lg" radius="xl" />
        <Text>
          By:{" "}
          <Text component="span" weight={500}>
            {author.name}
          </Text>
        </Text>
        <Text>{date}</Text>
      </Group>
    </Stack>
  )
}
