import {Button, Card, Center, Divider, Group, Text, Title} from "@mantine/core"
import {BigNumber, utils} from "ethers"
import React, {useState} from "react"
import EstimatedLoss from "../loss-estimates/EstimatedLoss"
import SaleModal from "./SaleModal"

export default function SaleSummary({
  nfts,
  fee,
  selectedNFTs,
  estimatedLosses,
  setEstimatedLosses,
  setEstimatedTokens,
  setSelectedNFTs,
  setLossModelOpened
}) {
  const [opened, setOpened] = useState(false)
  const totalItems = nfts.reduce((acc, nftDataWithCount) => acc + nftDataWithCount[1], 0)
  const totalFee = BigNumber.from(fee).mul(totalItems)

  return (
    <>
      <SaleModal
        nfts={nfts}
        totalFee={totalFee}
        opened={opened}
        setOpened={setOpened}
        estimatedLosses={estimatedLosses}
      />
      <Card withBorder sx={{borderColor: "black"}}>
        <Card.Section p="lg" withBorder sx={{borderColor: "black !important"}}>
          <Title order={3}>Order Summary</Title>
        </Card.Section>
        <Card.Section inheritPadding px="xl" py="md">
          <Group position="apart" mb="sm">
            <Text weight={500}>We will receive:</Text>
            <Text>
              {totalItems} item{totalItems === 1 ? "" : "s"}
            </Text>
          </Group>
          <Group position="apart" mb="sm">
            <Text weight={500}>Price / item:</Text>
            <Text>1 gwei</Text>
          </Group>
          <Divider mb="xs" color="black" size="sm" />
          <Group position="apart" mb="xl">
            <Text weight={500}>You will receive:</Text>
            <Text>{totalItems} gwei</Text>
          </Group>
          <EstimatedLoss
            selectedNFTs={selectedNFTs}
            setSelectedNFTs={setSelectedNFTs}
            estimatedLosses={estimatedLosses}
            setEstimatedLosses={setEstimatedLosses}
            setEstimatedTokens={setEstimatedTokens}
            setOpened={setLossModelOpened}
            size="md"
          />
          <Group mt="sm" position="apart" mb="sm">
            <Text>Service fee / item:</Text>
            <Text>{utils.formatEther(fee)} ETH</Text>
          </Group>
          <Group position="apart">
            <Text weight={500}>Total fees (before gas):</Text>
            <Text weight={500}>{utils.formatEther(totalFee)} ETH</Text>
          </Group>
        </Card.Section>

        <Card.Section mt="md" inheritPadding>
          <Center>
            <Button fullWidth onClick={() => setOpened(true)} mb="md">
              Confirm
            </Button>
          </Center>
        </Card.Section>
      </Card>
    </>
  )
}
