import {Contract} from "@ethersproject/contracts"
import {Button, Center, Container, Grid, Loader, Stack, Text, Title} from "@mantine/core"
import {IconChevronLeft} from "@tabler/icons"
import {useCall, useEthers} from "@usedapp/core"
import {utils} from "ethers"
import React, {useState} from "react"
import {Navigate, useLocation, useNavigate} from "react-router-dom"
import junkyardAbi from "../abis/Junkyard.json"
import SaleSummary from "../components/sale/SaleSummary"
import SavingsEstimateModal from "../components/loss-estimates/SavingsEstimateModal"
import SelectedNFTsScroll from "../components/nft-selection/SelectedNFTsScroll"
import {CHAIN_ID, JUNKYARD_ADDRESS} from "../constants"

export default function SaleConfirmation() {
  const {account} = useEthers()
  const location = useLocation()
  let navigate = useNavigate()

  const [selectedNFTs, setSelectedNFTs] = useState(location.state?.selectedNFTs)
  const [estimatedTokens, setEstimatedTokens] = useState(
    location.state?.estimatedTokens || new Set()
  )
  const [estimatedLosses, setEstimatedLosses] = useState(location.state?.estimatedLosses || null)
  const [opened, setOpened] = useState(false)

  const {value} =
    useCall(
      {
        contract: new Contract(JUNKYARD_ADDRESS, new utils.Interface(junkyardAbi)),
        method: "fee",
        args: []
      },
      {chainId: CHAIN_ID}
    ) ?? {}
  if (!location.state || !account) {
    return <Navigate to="/" />
  }

  const nfts = Object.values(selectedNFTs)

  if (nfts) {
    return (
      <Container py="xl">
        <SavingsEstimateModal
          opened={opened}
          setOpened={setOpened}
          selectedNFTs={selectedNFTs}
          setSelectedNFTs={setSelectedNFTs}
          setEstimatedTokens={setEstimatedTokens}
          setEstimatedLosses={setEstimatedLosses}
        />
        <Title>Ok, so you want to sell these NFTs?</Title>
        <Text>Please double check, as this transaction is not reversable!</Text>
        <Button
          pl={0}
          compact
          variant="subtle"
          onClick={() =>
            navigate("/", {
              state: {
                nftData: location.state?.nftData,
                selectedNFTs: selectedNFTs,
                estimatedLosses: estimatedLosses,
                estimatedTokens: estimatedTokens
              }
            })
          }
          mt="xl"
        >
          <IconChevronLeft mr="-xs" p={0} />
          <Text>Go back</Text>
        </Button>
        {value ? (
          <Grid>
            <Grid.Col span={7}>
              <Stack>
                <SelectedNFTsScroll nfts={nfts} textSize="lg" />
              </Stack>
            </Grid.Col>
            <Grid.Col span={5}>
              <SaleSummary
                fee={value[0].toString()}
                nfts={nfts}
                estimatedLosses={estimatedLosses}
                setEstimatedLosses={setEstimatedLosses}
                setEstimatedTokens={setEstimatedTokens}
                selectedNFTs={selectedNFTs}
                setSelectedNFTs={setSelectedNFTs}
                setLossModelOpened={setOpened}
              />
            </Grid.Col>
          </Grid>
        ) : (
          <Center>
            <Stack align="center">
              <Loader />
              <Text italic color="dimmed">
                Loading summary...
              </Text>
            </Stack>
          </Center>
        )}
      </Container>
    )
  } else {
    return <Navigate to="/" />
  }
}
