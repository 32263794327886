import {
  Burger,
  Container,
  createStyles,
  Group,
  Header,
  Paper,
  Title,
  Transition
} from "@mantine/core"
import {useDisclosure} from "@mantine/hooks"
import React from "react"
import {Link} from "react-router-dom"
import Checkmark from "./Checkmark"

const HEADER_HEIGHT = 70

const useStyles = createStyles(theme => ({
  root: {
    marginBottom: -(theme.spacing.xl + 20),
    position: "relative",
    zIndex: 2
  },

  dropdown: {
    position: "absolute",
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 3,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: "hidden",

    [theme.fn.largerThan("sm")]: {
      display: "none"
    }
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%"
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none"
    }
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none"
    }
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0]
    },

    [theme.fn.smallerThan("sm")]: {
      borderRadius: 0,
      padding: theme.spacing.md
    }
  }
}))

export default function NavBar({links}) {
  const [opened, {toggle, close}] = useDisclosure(false)
  const {classes} = useStyles()

  const items = links.map(link => (
    <Link key={link.label} onClick={() => close()} to={link.link} className={classes.link}>
      {link.label}
    </Link>
  ))

  return (
    <Header
      height={HEADER_HEIGHT}
      className={classes.root}
      withBorder
      sx={{borderColor: "#C0C0C0"}}
    >
      <Container className={classes.header}>
        <Link to="/" style={{color: "black", textDecoration: "none"}}>
          <Group spacing="xs">
            <Checkmark />
            <Title ml="-xs">NFT Tax Saver</Title>
          </Group>
        </Link>
        <Title></Title>
        <Group spacing={5} className={classes.links}>
          {items}
        </Group>

        <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />

        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {styles => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {items}
            </Paper>
          )}
        </Transition>
      </Container>
    </Header>
  )
}
