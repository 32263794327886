import React, {useState} from "react"
import {Button, Card, Flex, Loader, Modal, Stack, Text, Title} from "@mantine/core"
import {IconArrowRight, IconBrandTwitter} from "@tabler/icons"
import {useEthers} from "@usedapp/core"
import Web3ModalButton from "../account/Web3ModalButton"

const getTwitterText = (losses, refCode) =>
  losses
    ? `I harvested an estimated $${losses} USD in losses using @nfttaxsaver and saved on my taxes! Try it out here: https://nfttaxsaver.xyz${
        refCode ? `?ref=${refCode}` : ""
      }`
    : `I saved on my taxes by using @nfttaxsaver to tax loss harvest my NFTs! Try it out here: https://nfttaxsaver.xyz${
        refCode ? `?ref=${refCode}` : ""
      }`
const getTwitterUrl = text => `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`

const Tweet = ({losses, refCode, onCreateRefLink}) => {
  const {library, account} = useEthers()
  const [loading, setLoading] = useState(false)
  const [modalOpened, setModalOpened] = useState(false)

  const handleCreateLink = async () => {
    setLoading(true)
    const code = await onCreateRefLink()
    setLoading(false)
    window.open(getTwitterUrl(getTwitterText(losses, code)), "_blank")
    setModalOpened(false)
  }

  const twitterText = getTwitterText(losses, refCode)
  const url = getTwitterUrl(twitterText)

  return (
    <>
      <Modal
        opened={modalOpened}
        onClose={() => {
          setModalOpened(false)
        }}
        closeOnEscape={!loading}
        withCloseButton={!loading}
        size="60%"
        padding="30px"
      >
        <Title>Create referral link?</Title>
        <Text sx={{marginTop: "20px"}}>
          Do you want to create a custom referral link to include in your Tweet?
        </Text>
        <Text>You'll earn $1 for every NFT sold using your link.</Text>
        <Text size="sm" italic sx={{marginBottom: "20px"}}>
          Terms and conditions apply.
        </Text>
        {loading ? (
          <Loader />
        ) : (
          <Flex>
            {library && account ? (
              <Button onClick={handleCreateLink}>Yes, create my custom on-chain link</Button>
            ) : (
              <Web3ModalButton text="Yes (connect wallet)" size="sm" />
            )}
            <Button
              variant="outline"
              sx={{marginLeft: "10px"}}
              href={url}
              component="a"
              target="_blank"
              onClick={() => {
                setModalOpened(false)
              }}
            >
              No, skip
            </Button>
          </Flex>
        )}
      </Modal>
      <Card radius="lg" p="xl" sx={{backgroundColor: "white", flex: "1", alignSelf: "stretch"}}>
        <Stack>
          <IconBrandTwitter size={40} style={{alignSelf: "flex-end", marginRight: 15}} />
          <Text size="xl">Tweet about us!</Text>
          <Card.Section inheritPadding>
            <Button
              component={refCode ? "a" : undefined}
              href={refCode ? url : undefined}
              target={refCode ? "_blank" : undefined}
              onClick={
                refCode
                  ? undefined
                  : () => {
                      setModalOpened(true)
                    }
              }
              variant="outline"
              radius="xl"
              sx={{borderWidth: "2px"}}
            >
              <IconArrowRight />
            </Button>
          </Card.Section>
          <Text size="sm" italic>
            {twitterText}
          </Text>
        </Stack>
      </Card>
    </>
  )
}

export default Tweet
