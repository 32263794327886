import React from "react"
import {Alchemy} from "alchemy-sdk"
import {Image, Center, Loader, Text} from "@mantine/core"
import {BigNumber, utils} from "ethers"
import {SETTINGS, ALCHEMY_API_URL} from "../constants"
import {shortenAddress} from "@usedapp/core"

/**
 * Estimated losses for the given set of NFT keys and their addresses. All estimates are cached in the browser's localStorage
 * to avoid having to make this expensive call often.
 *
 * @param {string} account
 * @param {Set<string>} keys
 * @param {string[]} addresses
 * @returns
 */
async function getLossEstimates(account, keys, addresses) {
  const estimatedTokens = {}

  // only make the API call if there are still tokens to estimate
  if (addresses) {
    const options = {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/json"
      },
      body: JSON.stringify({
        id: 1,
        jsonrpc: "2.0",
        method: "alchemy_getAssetTransfers",
        params: [
          {
            fromBlock: "0x0",
            toBlock: "latest",
            category: ["erc721"],
            contractAddresses: addresses,
            withMetadata: false,
            excludeZeroValue: true,
            maxCount: "0x3e8",
            order: "desc",
            toAddress: account
          }
        ]
      })
    }

    const resp = await fetch(ALCHEMY_API_URL, options)
    const result = await resp.json()
    const transfers = result.result.transfers

    for (const transfer of transfers) {
      const nftDatum = {
        contractAddress: transfer.rawContract.address,
        id: BigNumber.from(transfer.tokenId).toString()
      }
      const key = getNFTKey(nftDatum)
      if (keys.has(key) && !(key in estimatedTokens)) {
        const estimate = await getTransferValue(transfer.hash)
        // estimate could be null here, but we still want to put it in the estimations because we don't want to accidentally use an older transfer with value as the loss estimate
        estimatedTokens[key] = estimate
        window.localStorage.setItem(key, estimate)
      }
    }
  }

  return estimatedTokens
}

async function getTransferValue(hash) {
  const MIN_COINBASE_TIME_GRANULARITY = 60 // seconds
  const alchemy = new Alchemy(SETTINGS)
  const transaction = await alchemy.core.getTransaction(hash)
  const block = await alchemy.core.getBlock(transaction.blockHash)
  const value = transaction.value
  const timestamp = block.timestamp
  const resp = await fetch(
    `https://api.exchange.coinbase.com/products/ETH-USD/candles?granularity=${MIN_COINBASE_TIME_GRANULARITY}&start=${timestamp}&end=${
      timestamp + MIN_COINBASE_TIME_GRANULARITY
    }`,
    {method: "GET"}
  )
  const result = await resp.json()
  let oneETHUSDValue
  // if data is missing for this timestamp, fall back on day closing price of eth
  if (result.length > 0) {
    oneETHUSDValue = result[0][1]
  } else {
    const date = new Date(timestamp * 1000).toISOString().slice(0, 10)
    const response = await fetch(`https://api.coinbase.com/v2/prices/BTC-USD/spot?date=${date}`)
    const json = await response.json()
    oneETHUSDValue = json?.data?.amount
  }
  if (!oneETHUSDValue) {
    return null
  } else {
    oneETHUSDValue = Number(oneETHUSDValue)
    const ethValue = Number(utils.formatEther(value))
    return Number(ethValue * oneETHUSDValue).toFixed(2)
  }
}

async function getNFTsForAccount(account) {
  const apiRes = await fetch(
    `${ALCHEMY_API_URL}/getNFTs?filters[]=AIRDROPS&filters[]=SPAM&owner=${account}&withMetadata=true`
  )
  const nfts = (await apiRes.json()).ownedNfts
  const result = []
  for (const nft of nfts) {
    try {
      const contractAddress = nft.contract.address
      if (!contractAddress) {
        throw new Error("Missing contract address")
      }
      const tokenType = nft.tokenType ?? nft.id.tokenMetadata.tokenType
      if (!["ERC721", "ERC1155"].includes(tokenType)) {
        throw new Error(`Unknown ot missing token type ${tokenType}`)
      }
      const id = getNFTId(nft)
      if (!id) {
        throw new Error("Missing NFT ID")
      }
      const balance = Number(nft.balance)
      if (isNaN(balance)) {
        throw new Error("Missing or malformed balance")
      }
      result.push({
        id,
        balance,
        contractAddress,
        contractName: nft.contract.name ?? nft.contractMetadata?.name,
        contractSymbol: nft.contract.symbol ?? nft.contractMetadata?.symbol,
        tokenType,
        image: getNFTImage(nft),
        title: getNFTTitle(nft)
      })
    } catch (e) {
      console.error("Failed to process nft: ", e)
    }
  }
  return result
}

function getNFTImage(nftDatum) {
  const imageUri = nftDatum.media[0]?.gateway || nftDatum.tokenUri?.raw
  if (!imageUri) {
    return null
  }

  return imageUri.startsWith("ipfs://")
    ? imageUri.replace("ipfs://", "https://ipfs.io/ipfs/")
    : imageUri
}

function getNFTKey(nft) {
  return nft.contractAddress + nft.id
}

// TODO: check
function getMediaComponent(nftDatum, size) {
  const imageUrl = nftDatum.image
  if (!imageUrl) {
    return (
      <Image
        alt={`Image for ${nftDatum["title"]}`}
        fit="contain"
        width={size}
        height={size}
        withPlaceholder
        placeholder={
          <Center p="md">
            <Text sx={{textAlign: "center"}}>No image found for NFT</Text>
          </Center>
        }
      />
    )
  }

  return imageUrl.split(".").pop() === "mp4" ? (
    <video src={imageUrl} loop autoPlay muted width={size} height={size} />
  ) : (
    <Image
      src={imageUrl}
      alt={`Image for ${nftDatum["title"]}`}
      fit="contain"
      width={size}
      height={size}
      withPlaceholder
      placeholder={
        <Center sx={{height: size, width: size}}>
          <Loader />
        </Center>
      }
    />
  )
}

function getNFTId(nftDatum) {
  return (
    nftDatum["tokenId"] ?? (nftDatum.id?.tokenId && BigNumber.from(nftDatum.id.tokenId).toString())
  )
}

function getNFTTitle(nftDatum) {
  return nftDatum["title"] || `#${getNFTId(nftDatum)}`
}

function getContractLabel(nftDatum) {
  const address = nftDatum.contractAddress
  const {contractName, contractSymbol} = nftDatum
  const name = contractName
    ? `${contractName}${contractSymbol ? ` (${contractSymbol})` : ""}`
    : null
  return name ? `${shortenAddress(address)} - ${name}` : `${shortenAddress(address)}`
}

const ERC165Abi = [
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4"
      }
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    stateMutability: "view",
    type: "function"
  }
]

const ERC1155InterfaceId = "0xd9b67a26"
const ERC721InterfaceId = "0x80ac58cd"

export {
  getLossEstimates,
  getNFTsForAccount,
  getNFTKey,
  getMediaComponent,
  getContractLabel,
  ERC165Abi,
  ERC1155InterfaceId,
  ERC721InterfaceId
}
