import React from "react"
import {useEthers} from "@usedapp/core"
import {Button, CopyButton, Group} from "@mantine/core"
import {IconExternalLink} from "@tabler/icons"
import {ETHERSCAN_URL} from "../../constants"

export default function AccountBar() {
  const {account, deactivate} = useEthers()

  return (
    <Group>
      <Button variant="outline" compact onClick={() => deactivate()}>
        Disconnect
      </Button>
      <CopyButton value={account}>
        {({copied, copy}) => (
          <Button compact variant="outline" onClick={copy}>
            {copied ? "Copied address" : "Copy address"}
          </Button>
        )}
      </CopyButton>
      <Button
        component="a"
        target="_blank"
        href={`${ETHERSCAN_URL}/address/${account}`}
        variant="outline"
        compact
        leftIcon={<IconExternalLink size={14} />}
      >
        View on explorer
      </Button>
    </Group>
  )
}
