import {BigNumber} from "ethers"

/**
 * Convert an array of {NFT object, count} tuples into erc junk groups that the contract can consume
 * @returns Array of erc721 junk group, erc1155 junk group, and all unique contract addresses
 */
function junkGroupsFromSelectedNFTs(selectedNFTs) {
  const erc1155JunkGroups = {}
  const erc721JunkGroups = {}
  for (const nftDatumWithCount of selectedNFTs) {
    const nftDatum = nftDatumWithCount[0]
    const count = nftDatumWithCount[1]
    const address = nftDatum.contractAddress
    const tokenId = BigNumber.from(nftDatum.id).toNumber()
    if (nftDatum.tokenType === "ERC1155") {
      if (address in erc1155JunkGroups) {
        erc1155JunkGroups[address][0].push(tokenId)
        erc1155JunkGroups[address][1].push(count)
      } else {
        erc1155JunkGroups[address] = [[tokenId], [count]]
      }
    } else {
      if (address in erc721JunkGroups) {
        erc721JunkGroups[address].push(tokenId)
      } else {
        erc721JunkGroups[address] = [tokenId]
      }
    }
  }

  const allAddresses = []
  const finalERC721JunkGroups = []
  for (const [address, tokenIds] of Object.entries(erc721JunkGroups)) {
    finalERC721JunkGroups.push([address, tokenIds])
    allAddresses.push(address)
  }
  const finalERC1155JunkGroups = []
  for (const [address, junkGroupData] of Object.entries(erc1155JunkGroups)) {
    finalERC1155JunkGroups.push([address, junkGroupData[0], junkGroupData[1]])
    allAddresses.push(address)
  }

  return [finalERC721JunkGroups, finalERC1155JunkGroups, allAddresses]
}

function getUniqueContractAddressesFromNFTs(nfts) {
  const addresses = new Set()

  for (const nft of nfts) {
    addresses.add(nft.contractAddress)
  }

  return Array.from(addresses)
}

// TODO: test this
function sortLosses(selectedNFTs, losses) {
  const sortedLosses = []
  for (const key in losses) {
    sortedLosses.push([key, selectedNFTs[key], losses[key]])
  }

  // descending
  sortedLosses.sort((a, b) => {
    if (a[2] === null) {
      return 1
    } else if (b === null) {
      return -1
    } else {
      return b[2] - a[2]
    }
  })

  for (const key in selectedNFTs) {
    if (!(key in losses)) {
      sortedLosses.push([key, selectedNFTs[key], undefined])
    }
  }

  return sortedLosses
}

export {junkGroupsFromSelectedNFTs, getUniqueContractAddressesFromNFTs, sortLosses}
