import React from "react"
import {Container, Stack, Title, Text} from "@mantine/core"
import {Helmet} from "react-helmet"

export default function HowItWorks() {
  return (
    <>
      <Helmet>
        <title>How It Works | NFT Tax Saver</title>
      </Helmet>
      <Container>
        <Stack>
          <Title mb="lg">How it works</Title>
          <Text>
            <Text component="span" weight={700}>
              Step 1. Connect your wallet.
            </Text>{" "}
            This will allow you to see all of your NFTs in your wallet. Connecting your wallet
            doesn’t grant any permissions to our dapp – it just gives us the ability to display your
            NFTs on your screen.
          </Text>
          <Text>
            <Text component="span" weight={700}>
              Step 2. Select the NFT(s) you want to sell.
            </Text>{" "}
            There are multiple ways to do this:
          </Text>
          <Text ml="xl" mt="-xs">
            a. Click directly on the picture of the NFT(s). Use the arrows to navigate, and filter
            based on collection.
          </Text>
          <Text ml="xl" mt="-xs">
            b. Choose NFTs found via our opportunity finder. This neat feature will automatically
            audit your wallet transaction history to sort your NFTs by largest unrealized loss.
          </Text>

          <Text>
            <Text component="span" weight={700}>
              Step 3. Click “Continue to Checkout.”
            </Text>{" "}
            This page will show you a summary of the NFTs you are about to sell, how much you’ll
            earn, and how much of a loss you will realize. Note that there is a minor service fee
            for each NFT you sell.
          </Text>
          <Text>
            <Text component="span" weight={700}>
              Step 4. Click confirm Sale; this will trigger separate approval transactions for each
              NFT collection you’ve selected.
            </Text>{" "}
            Be sure to approve every transaction or else the entire set of transactions will revert.
          </Text>
          <Text>
            <Text component="span" weight={700}>
              Step 5. Approve the final transaction.
            </Text>{" "}
            This transaction will have a gas cost based on the number of NFTs you’re selling. There
            is no way around this gas cost because the gas cost comes directly from the original
            contracts of the NFTs you’re selling (specifically, the transfer function).
          </Text>
          <Text>
            <Text component="span" weight={700}>
              Step 6. Congrats!
            </Text>{" "}
            You’ve sold your NFTs and can now offset your tax bill with these losses!
          </Text>
        </Stack>
      </Container>
    </>
  )
}
