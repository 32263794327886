export default function Checkmark() {
  return (
    <svg width="50px" height="50px" version="1.1" viewBox="0 0 752 752">
      <defs>
        <clipPath id="a">
          <path d="m139.21 166h473.58v420h-473.58z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <path d="m612.79 166.97-1.6328 17.965c-150.24 111.05-230.26 248.22-287.41 365.8-34.293 22.863-58.789 53.891-73.488 17.965-17.965-47.359-57.156-137.18-111.05-155.14 22.863-26.129 44.094-32.66 70.219-29.395 34.293 4.8984 65.32 70.219 78.387 99.617 70.219-117.58 199.23-251.49 324.97-316.81z" />
      </g>
    </svg>
  )
}
