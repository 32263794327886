import React from "react"
import {Helmet} from "react-helmet"
import {Container, Stack, Title, Center} from "@mantine/core"

export default function NoMatch() {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container>
        <Center>
          <Stack>
            <Title mb="lg">Uh oh! This page doesn't exist!</Title>
          </Stack>
        </Center>
      </Container>
    </>
  )
}
