import React, {useState, useRef} from "react"
import {useEthers} from "@usedapp/core"
import {Helmet} from "react-helmet"
import {
  Container,
  Center,
  Stack,
  Button,
  Title,
  Text,
  Modal,
  Loader,
  useMantineTheme
} from "@mantine/core"
import {useLocation} from "react-router-dom"
import Web3ModalButton from "../components/account/Web3ModalButton"
import AccountHeader from "../components/account/AccountHeader"
import NFTSelector from "../components/nft-selection/NFTSelector"
import {getNFTsForAccount, getNFTKey, getLossEstimates} from "../utils/nftUtils"
import {getUniqueContractAddressesFromNFTs} from "../utils/junkyardUtils"
import HomeHero from "../components/hero/HomeHero"

export default function Home() {
  const ref = useRef(null)
  const {account} = useEthers()
  const location = useLocation()
  const theme = useMantineTheme()
  const [estimatedLosses, setEstimatedLosses] = useState(null)
  const [estimatedTokens, setEstimatedTokens] = useState(new Set())
  const [opened, setOpened] = useState(false)
  const [showSelector, setShowSelector] = useState(false)
  const [nftData, setNFTData] = useState([])
  const [selectedNFTs, setSelectedNFTs] = useState({})
  const [calculating, setCalculating] = useState(false)
  const [error, setError] = useState("")

  const pickNFTs = async () => {
    setCalculating(true)
    setOpened(true)
    try {
      const _selectedNFTs = {}
      const nfts = await getNFTsForAccount(account)
      const erc721s = nfts.filter(nft => nft.tokenType === "ERC721")
      const addresses = getUniqueContractAddressesFromNFTs(erc721s)
      const erc721Keys = new Set(erc721s.map(nft => getNFTKey(nft)))
      const _estimatedLosses = await getLossEstimates(account, erc721Keys, addresses)
      for (const nft of nfts) {
        const key = getNFTKey(nft)
        if (_estimatedLosses[key]) {
          _selectedNFTs[key] = [nft, nft.balance]
        }
      }
      let total = Object.values(_estimatedLosses).reduce((acc, a) => acc + Number(a), 0)

      setEstimatedLosses(total.toFixed(2))
      setEstimatedTokens(new Set(Object.keys(_selectedNFTs)))
      setNFTData(nfts)
      setSelectedNFTs(_selectedNFTs)
      setShowSelector(true)
      setOpened(false)
    } catch (e) {
      console.error(e)
      setError("There was an error calculating your losses.")
    }
    setCalculating(false)
  }

  const handleClick = () => {
    ref.current?.scrollIntoView({behavior: "smooth"})
  }

  const startComponent =
    location.state &&
    location.state.nftData.length > 0 &&
    Object.keys(location.state.selectedNFTs).length > 0 ? (
      <NFTSelector
        existingNFTData={location.state.nftData}
        existingSelectedNFTs={location.state.selectedNFTs}
        estimatedLosses={location.state.estimatedLosses}
        estimatedTokens={location.state.estimatedTokens}
      />
    ) : showSelector ? (
      <NFTSelector
        existingNFTData={nftData}
        existingSelectedNFTs={selectedNFTs}
        estimatedLosses={estimatedLosses}
        estimatedTokens={estimatedTokens}
      />
    ) : (
      <Container>
        <Stack>
          <Button onClick={pickNFTs}>Recommend NFTs with the biggest tax savings</Button>
          <Button onClick={() => setShowSelector(true)}>Let me manually select NFTs</Button>
        </Stack>
      </Container>
    )

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        withCloseButton={false}
        centered
        closeOnClickOutside={!calculating}
        closeOnEscape={!calculating}
        overlayColor={theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[2]}
        overlayOpacity={0.55}
        overlayBlur={3}
        size="75%"
      >
        <Center py="xl">
          {error ? (
            <Text>{error}</Text>
          ) : (
            <Stack align="center">
              <Loader />
              <Text>Picking the best NFTs for you to harvest...</Text>
            </Stack>
          )}
        </Center>
      </Modal>
      <Helmet>
        <title>NFT Tax Saver</title>
      </Helmet>
      <Stack>
        <HomeHero handleClick={handleClick} />
        <Center py="xl" sx={{marginTop: "10vh"}} ref={ref}>
          {account ? (
            <Stack>
              <AccountHeader />
            </Stack>
          ) : (
            <Stack align="center" spacing="xl">
              <Title>Connect your wallet to start saving money!</Title>
              <Web3ModalButton />
            </Stack>
          )}
        </Center>
        {account && startComponent}
      </Stack>
    </>
  )
}
