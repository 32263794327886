import React, {useState, useEffect} from "react"
import {useEthers} from "@usedapp/core"
import {
  Button,
  Center,
  MultiSelect,
  Loader,
  Stack,
  Text,
  Title,
  Pagination,
  Grid,
  Container
} from "@mantine/core"
import NFTSelectorGrid from "./NFTSelectorGrid"
import SelectedNFTs from "./SelectedNFTs"
import {getContractLabel, getNFTKey, getNFTsForAccount} from "../../utils/nftUtils"

function getMultiSelectFilterValues(nftData) {
  const contracts = nftData.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.contractAddress]: getContractLabel(cur)
    }),
    {}
  )

  return Object.keys(contracts).reduce(
    (acc, cur) => [
      ...acc,
      {
        value: cur,
        label: contracts[cur]
      }
    ],
    []
  )
}

function filterSelectedContracts(filters, nftData) {
  const filterAddresses = new Set(filters)
  const filteredData = []

  for (const nftDatum of nftData) {
    if (filterAddresses.has(nftDatum.contractAddress)) {
      filteredData.push(nftDatum)
    }
  }

  return filteredData
}

export default function NFTSelector({
  existingNFTData,
  existingSelectedNFTs,
  estimatedLosses,
  estimatedTokens
}) {
  const NFTS_PER_PAGE = 9
  const {account} = useEthers()
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [nftData, setNFTData] = useState([])
  const [selectedNFTs, setSelectedNFTs] = useState({})
  const [activePage, setPage] = useState(1)
  const [filterAddresses, setFilterAddresses] = useState([])
  const multiSelectValues = getMultiSelectFilterValues(nftData)
  const [filteredNFTData, setFilteredNFTData] = useState([])

  useEffect(() => {
    const _filteredNFTData =
      filterAddresses.length === 0 ? nftData : filterSelectedContracts(filterAddresses, nftData)
    setFilteredNFTData(_filteredNFTData)
  }, [filterAddresses, nftData])

  const selectAllFiltered = () => {
    const nftsToSelect = {}
    for (const nftDatum of filteredNFTData) {
      const nftKey = getNFTKey(nftDatum)
      if (nftKey in selectedNFTs) {
        continue
      }

      nftsToSelect[nftKey] = [nftDatum, nftDatum.balance]
    }

    setSelectedNFTs({
      ...selectedNFTs,
      ...nftsToSelect
    })
  }

  const unselectAllFiltered = () => {
    const newSelectedNFTs = {...selectedNFTs}
    for (const nftDatum of filteredNFTData) {
      delete newSelectedNFTs[getNFTKey(nftDatum)]
    }

    setSelectedNFTs(newSelectedNFTs)
  }

  const getNFTsForPage = page => {
    const bottomBound = page - 1
    return filteredNFTData.slice(bottomBound * NFTS_PER_PAGE, page * NFTS_PER_PAGE)
  }

  const fetchData = async () => {
    try {
      if (existingNFTData.length > 0 && Object.keys(existingSelectedNFTs).length > 0) {
        setIsLoaded(true)
        setNFTData(existingNFTData)
        setSelectedNFTs(existingSelectedNFTs)
        // make sure data doesn't get saved even after refresh
        window.history.replaceState(null, "")
        return
      }

      const nfts = await getNFTsForAccount(account)
      setNFTData(nfts)
    } catch (e) {
      setError(error)
    }
    setIsLoaded(true)
  }

  // fetch all NFTs that the user owns
  useEffect(() => {
    fetchData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    return <Text color="red">Error loading NFTs: {error.message}</Text>
  } else if (!isLoaded) {
    return (
      <Center>
        <Stack align="center">
          <Loader />
          <Text italic color="dimmed">
            Loading your NFTs...
          </Text>
        </Stack>
      </Center>
    )
  } else {
    return (
      <Container size="md" style={{width: "100%"}}>
        <Grid gutter="xl" justify="center" grow align="stretch">
          <Grid.Col span={8}>
            <Stack>
              <Title>Which NFTs do you want to sell?</Title>
              <Text>(Select as many as you'd like)</Text>
              <Grid gutter="xs">
                <Grid.Col span={6}>
                  <MultiSelect
                    data={multiSelectValues}
                    placeholder="Select contracts to filter by"
                    searchable
                    limit={10}
                    nothingFound="Nothing found"
                    onChange={setFilterAddresses}
                    clearable
                  />
                </Grid.Col>
                <Grid.Col span={6} pl="lg">
                  <Button mr="xs" onClick={selectAllFiltered}>
                    Select All
                  </Button>
                  <Button onClick={unselectAllFiltered}>Unselect All</Button>
                </Grid.Col>
              </Grid>
              <NFTSelectorGrid
                nftData={getNFTsForPage(activePage)}
                selectedNFTs={selectedNFTs}
                setSelectedNFTs={setSelectedNFTs}
              />
              <Pagination
                page={activePage}
                onChange={setPage}
                total={Math.ceil(filteredNFTData.length / NFTS_PER_PAGE)}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4}>
            <SelectedNFTs
              nftData={nftData}
              selectedNFTs={selectedNFTs}
              setSelectedNFTs={setSelectedNFTs}
              existingEstimatedLosses={estimatedLosses}
              existingEstimatedTokens={estimatedTokens}
            />
          </Grid.Col>
        </Grid>
      </Container>
    )
  }
}
