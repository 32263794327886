import React from "react"
import {Link} from "react-router-dom"
import {Container, Stack, Title, Text} from "@mantine/core"
import BlogHeading from "../../components/blog/BlogHeading"
import {Helmet} from "react-helmet"
import {BLOG_TITLE_HOW_TO_HARVEST} from "../../constants"

export default function HowToLossHarvest() {
  return (
    <>
      <Helmet>
        <title>How to Tax Loss Harvest NFTs: 2022 Guide | NFT Tax Saver</title>
        <meta
          name="description"
          content="A compete guide on how to use tax loss harvesting strategies with NFTs to reduce your tax bill in 2022."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Container>
        <Stack>
          <BlogHeading
            title={BLOG_TITLE_HOW_TO_HARVEST}
            author={{name: "The NFT Tax Saver Team"}}
            date={"October 23, 2022"}
          />
          <Text>
            Over the past few years, many retail and institutional investors have begun to buy and
            sell NFTs as part of their investment strategies. Although the first ever NFT was minted
            back in 2014, most mainstream investors had never heard of NFTs until 2021 when projects
            like CryptoPunks, Bored Ape Yacht Club, and Axie Infinity surged in popularity and
            value. These marquee collections ended up kicking off the “NFT Bull Run” of 2021 and
            2022, leading to an explosion in the number of NFT launches promising advancements in
            art, gaming, and community-building.
          </Text>
          <Text>
            While some were lucky enough to realize profits with NFTs, in reality, most investors
            have lost significant funds as floor prices torpedoed. Indeed, it is not unusual to see
            collections’ floor prices currently worth 99% less than their peak value. Worse yet, in
            the NFT space, sometimes project founders can completely disappear, leaving community
            members hanging in a process known as “getting rugpulled” or “rugged.” For those with
            little prior experience in crypto, these types of losses can be painful and hard to
            swallow.
          </Text>
          <Text>
            Luckily, there is a silver lining to suffering losses: through a process known as “tax
            loss harvesting,” investors can leverage their investment losses to offset the amount
            they need to pay for taxes. This guide explains how tax loss harvesting works for NFTs.
          </Text>
          <Text size="sm" italic>
            Note: this article should not be construed as official tax or legal advice. Always
            consult your own accountant or lawyer.
          </Text>
          <Title order={2}>What are NFTs?</Title>
          <Text>
            NFTs are “non-fungible tokens.” NFTs leverage the same underlying blockchain technology
            as cryptocurrencies like Bitcoin, Ethereum, and Solana, and ultimately enable digital
            ownership of tokens on a distributed ledger.
          </Text>
          <Text>
            However, whereas every BTC token or ETH token is identical to each other, each NFT token
            has the option to be completely unique. Alice’s bitcoin is no different from Bob’s
            bitcoin (they are “fungible”). But within a single NFT collection that contains 10,000
            tokens, for example, Token #1 has the potential to be different from Token #2, which is
            different from Token #3, and so on.
          </Text>
          <Title order={3}>NFTs vs Cryptocurrencies Example</Title>
          <Text>
            If Alice and Bob exchanged their 1 BTC with each other, they would be indifferent, as
            those BTC are the same. But if Alice and Bob exchanged their CryptoPunk NFTs with each
            other, they would end up with fundamentally different tokens. One might have sunglasses,
            while another might have the Zombie trait. As a result, Alice and Bob might not be ok
            with trading their NFTs with each other!
          </Text>
          <Text>
            The reason for this non-fungibility is that NFTs can be coded such that each token
            includes unique metadata, like a different name, image, or other arbitrary property.
            These unique metadata are why, within a single NFT collection like, say, Bored Ape Yacht
            Club, some tokens will have laser eyes, others will have golden fur, etc. The
            non-fungibility of NFTs enables ownership of unique items on the blockchain, which has
            many technologists and enthusiasts speculating about the ability for NFTs to upend
            several industries, including gaming, art, music, and more.
          </Text>
          <Title order={2}>How are NFTs taxed?</Title>
          <Text>
            NFTs are taxed whenever you realize a gain or loss. You “realize” a gain or loss
            whenever you sell an NFT.
          </Text>
          <Text>
            When you buy an NFT, the amount you pay for it is considered your “cost-basis.”
          </Text>
          <Text>
            Once you sell that NFT, the difference between the amount you sold it for and your
            “cost-basis” (the amount you originally acquired it for) is your “realized gain (or
            loss).”
          </Text>
          <Text>
            You are not taxed on your NFT unless you sell it. Even if your NFT is currently worth
            100x more than what you paid for it, until you sell it you are sitting on an “unrealized
            gain.” You will not be taxed on unrealized gains. Same goes for losses – if your NFT is
            worth 5% of what you originally paid for it, until you sell it you are sitting on an
            “unrealized loss,” which does not affect your taxes.
          </Text>
          <Title order={3}>Example of how NFT capital gains taxes work:</Title>
          <Text>
            Let’s say Alice buys an NFT for $1000. The value of the NFT increases to $1500. At this
            point, Alice has an “unrealized” gain of $500. But she does not owe any taxes on this
            gain yet.
          </Text>
          <Text>
            Next, Alice sells the NFT for $1500. At this point, she has “realized” her gain of $500.
            She now owes taxes on this $500.
          </Text>
          <Title order={3}>Example of how NFT capital loss taxes work: </Title>
          <Text>
            Bob buys an NFT for $1000. The value of the NFT falls to $700. At this point, Bob has an
            “unrealized” loss of $300. There are no implications for Bob’s taxes, yet.
          </Text>
          <Text>
            Next, Bob sells the NFT for $700. At this point, he has “realized” his loss of $300. He
            can now use this $300 to decrease his tax burden, since his overall capital gains are
            now lower by $300.
          </Text>
          <Title order={2}>How much are NFTs gains and losses taxed?</Title>
          <Text>
            The amount of tax you owe from trading NFTs will depend on a number of factors,
            including your income, your total capital gain (or loss), where you live, your marital
            status, and more. Usually, the higher your realized gains for NFT taxes, the more tax
            you will pay. If you can increase your realized losses, then you will pay less on NFT
            taxes. This is why NFT tax loss harvesting is important – by turning your unrealized
            losses into realized losses, you can decrease the amount of taxes you owe.
          </Text>
          <Text>
            <Link to="/blog/how-are-nfts-taxed">
              Check out our guides on how NFTs are taxed to learn more.
            </Link>
          </Text>
          <Title order={2}>What is NFT Tax Loss Harvesting?</Title>
          <Text>
            NFT tax loss harvesting is a technique used by investors and traders to decrease their
            total realized gains by selling NFTs at a loss. When you sell your NFTs at a loss, you
            “realize” a loss that decreases your capital gains for the year. Since your NFT taxes
            are calculated based on how much you’ve earned in capital gains, if you can decrease
            that number simply by selling NFTs that are already in loss anyway, you will pay less in
            taxes for the year.
          </Text>
          <Title order={3}>NFT Tax Loss Harvesting Example:</Title>
          <Text>
            Let’s say Charlie has made $10,000 in NFT profits this year. Assuming his tax rate is
            40%, he will need to pay $4,000 in taxes, leaving him with $6,000.
          </Text>
          <Text>
            However, Charlie remembers that he has a number of NFTs where he is sitting on an
            “unrealized loss.” For example, he bought an NFT for $2,000, but it is now worth $1400.
            He is sitting on an unrealized loss of $600. Another example is an NFT he originally
            bought for $500, but it is now worth $100. Here, he is sitting on an unrealized loss of
            $400.
          </Text>
          <Text>
            If Charlie adds up all of his unrealized losses, he realizes he is sitting on $1,000 of
            unrealized losses. If he does nothing, however, he will not be able to use those $1,000
            of losses to decrease his capital gains. Remember – unrealized losses don’t count for
            tax purposes.
          </Text>
          <Text>
            Instead, Charlie needs to “realize” his losses on those NFTs by selling them. If he
            sells his 2 NFTs which he originally paid a total of $2500 for, he will get $1500 in
            cash while realizing his loss of $1000. This $1000 realized loss can be used to decrease
            his realized NFT profits – $10,000 – down to $9000.
          </Text>
          <Text>
            Now, Charlie owes taxes only on $9000. Since his tax rate is 40%, he will only need to
            pay $3600 in taxes (instead of $4000). Post-tax, Charlie now has $6400 (instead of
            $6000). Charlie saved $400 in taxes just by selling NFTs that were already in loss
            anyway.
          </Text>
          <Title order={2}>Is NFT Tax Loss Harvesting legal?</Title>
          <Text>
            Some may refer to tax loss harvesting colloquially as an “NFT tax loophole.” In reality,
            tax loss harvesting is a perfectly legal technique when done correctly and can be used
            to decrease your tax burden.
          </Text>
          <Text>
            Tax loss harvesting is a technique used by investors even outside of NFTs and crypto. A
            quick Google search will reveal that reputable companies like Fidelity, Betterment, and
            Bank of America even discuss tax loss harvesting as a strategy they recommend people use
            to lower their tax bills. There is nothing wrong with strategically selling your assets
            to decrease your tax bill.
          </Text>
          <Title order={2}>How do I tax loss harvest my NFTs?</Title>
          <Text>
            There are many correct ways to tax loss harvest your NFTs, but there are also incorrect
            ways. We’ll review your options below and give you a recommendation for how we like to
            execute tax loss harvesting for NFTs.
          </Text>
          <Text>
            The most important principle to remember when attempting to sell your NFT for tax loss
            harvesting purposes is that the transaction needs to be an “arm’s length transaction.”
            Basically, this means that the two parties involved in the trade have to do so
            independently to satisfy their own economic interests.
          </Text>
          <Text>So what does this mean in practice?</Text>
          <Title order={2}>What are legal ways to sell NFTs for tax loss harvesting?</Title>
          <Text>
            Based on the definition of an arms-length transaction, the best way to tax loss harvest
            your NFT is to sell it to someone else. You can do this on popular NFT marketplaces like
            Opensea, MagicEden, etc. However, a major problem emerges – what if the NFT no longer
            has trading volume? What if no one on Opensea or MagicEden is around to buy my NFT
            anymore?
          </Text>
          <Title order={2}>How to sell NFTs with no volume? How do I sell rugged NFTs?</Title>
          <Text>
            Because NFTs are often hype-driven, it can be difficult to actually sell your NFTs once
            they fall out of favor. For example, you might list your NFT on Opensea, but there may
            be nobody on the other side to actually purchase your NFT. If no one is there to buy
            your NFT, you won’t be able to realize your loss for tax purposes.
          </Text>
          <Text>
            Another example is if the creator of your NFT has rugged and abandoned the community,
            you may find yourself in a position where you cannot sell your NFT. There may not be any
            liquidity, meaning there is no volume and there are no buyers.
          </Text>
          <Text>
            Luckily, we have a solution for this. NFT Tax Saver is a dapp we built that enables you
            to sell us any NFT for 1 gwei (equivalent to 0.0000001 ETH). This transaction would be
            considered an “arms-length transaction.” It’s quick, secure, and safe to use – just
            connect your wallet, select the NFTs you want to sell, and realize your losses. Learn
            more about NFT Tax Saver and start saving on taxes today.
          </Text>
          <Title order={2}>Illegal and Incorrect Ways to Tax Loss Harvest NFTs</Title>

          <Text>
            Based on the above definition, the following methods are illegal and incorrect ways to
            tax loss harvest your NFTs:
          </Text>
          <Title order={3}>Burning an NFT to realize a loss for taxes</Title>
          <Text>
            When you “burn” an NFT, you are actually just sending it to a dead wallet address that
            no one can access anymore. The IRS has not issued guidance on whether sending a token to
            a burn address counts as a realized loss, so we would not recommend doing this. Think
            about it: is the burn wallet address considered an independent party that’s trading to
            satisfy its own economic interests? No – no one operates that dead wallet.
          </Text>
          <Title order={3}>Selling an NFT to yourself to realize a loss for taxes</Title>
          <Text>
            When you sell an NFT to yourself, you are not satisfying the criteria of having two
            parties acting independently. Don’t try to buy an NFT in one wallet using another wallet
            – this would most likely be considered tax evasion. We do not recommend selling an NFT
            to yourself as a method of tax loss harvesting.
          </Text>
          <Title order={3}>
            Selling an NFT to a friend or family member to realize a loss for taxes
          </Title>
          <Text>
            When you sell an NFT to a friend or family member, it may be challenging to prove that
            you and your friend are two parties acting “independently.” Especially if the main
            reason your friend is purchasing your NFT is so that you can realize a loss for tax
            purposes, this will not be considered an arms-length transaction. We would recommend
            against this method to avoid headaches with the IRS in the future.
          </Text>
        </Stack>
      </Container>
    </>
  )
}
