import React, {useEffect} from "react"
import {createRoot} from "react-dom/client"
import App from "./App"
import {BrowserRouter, useLocation} from "react-router-dom"
import {Mainnet, DAppProvider, Goerli} from "@usedapp/core"
import {MantineProvider} from "@mantine/core"

// This is needed because react-router does not "reset" the page when switching pages. As such, we want to
// explicitly scroll to the top on page change
function ScrollToTop() {
  const {pathname} = useLocation()

  useEffect(() => {
    const canControlScrollRestoration = "scrollRestoration" in window.history
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = "manual"
    }

    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const config = {
  readOnlyUrls: {
    [Mainnet.chainId]: "https://mainnet.infura.io/v3/fe861e8731f24d3aa9c50931128201a3",
    [Goerli.chainId]: "https://goerli.infura.io/v3/fe861e8731f24d3aa9c50931128201a3"
  }
}

const container = document.getElementById("root")
const root = createRoot(container)
root.render(
  <BrowserRouter>
    <DAppProvider config={config}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{white: "#F2ECE5", loader: "dots", primaryColor: "dark"}}
      >
        <ScrollToTop />
        <App />
      </MantineProvider>
    </DAppProvider>
  </BrowserRouter>
)
