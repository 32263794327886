import React from "react"

import {Grid} from "@mantine/core"
import NFTCard from "./NFTCard"
import {getNFTKey} from "../../utils/nftUtils"

export default function NFTSelectorGrid({nftData, selectedNFTs, setSelectedNFTs}) {
  return (
    <Grid sx={{minHeight: "65vh"}}>
      {nftData.map(nft => (
        <Grid.Col key={getNFTKey(nft)} span={4}>
          <NFTCard nftDatum={nft} selectedNFTs={selectedNFTs} setSelectedNFTs={setSelectedNFTs} />
        </Grid.Col>
      ))}
    </Grid>
  )
}
