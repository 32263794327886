import React, {useState} from "react"
import {useEthers, shortenAddress} from "@usedapp/core"
import {Anchor, Button, Center, Stack, Text, Title, Loader, Modal} from "@mantine/core"
import {useNavigate} from "react-router-dom"
import {JUNKYARD_ADDRESS, ETHERSCAN_URL} from "../../constants"
import junkyardAbi from "../../abis/Junkyard.json"
import erc721Abi from "../../abis/ERC721.json"
import erc1155Abi from "../../abis/ERC1155.json"
import {ethers} from "ethers"
import {junkGroupsFromSelectedNFTs} from "../../utils/junkyardUtils"
import {ERC165Abi, ERC1155InterfaceId, ERC721InterfaceId} from "../../utils/nftUtils"

class ContractNotNFTError extends Error {
  constructor(message) {
    super(message)
    this.name = "ContractNotNFTError"
  }
}

export default function SaleModal({nfts, totalFee, opened, setOpened, estimatedLosses}) {
  const navigate = useNavigate()
  const {library, account} = useEthers()
  const [launchingApprovalRequests, setLaunchingApprovalRequests] = useState(false)
  const [pendingApprovals, setPendingApprovals] = useState({})
  const [pendingJunkTx, setPendingJunkTx] = useState(false)
  const [pendingJunkTxHash, setPendingJunkTxHash] = useState("")
  const [transactionFailedReason, setTransactionFailedReason] = useState("")
  const modalAllowedToClose =
    transactionFailedReason ||
    (!launchingApprovalRequests && !Object.values(pendingApprovals).length && !pendingJunkTx)

  const getApproval = async address => {
    const erc165Contract = new ethers.Contract(address, ERC165Abi, library)
    if (await erc165Contract.supportsInterface(ERC1155InterfaceId)) {
      const erc1155Contract = new ethers.Contract(address, erc1155Abi, library.getSigner())
      const isApproved = await erc1155Contract.isApprovedForAll(account, JUNKYARD_ADDRESS)
      if (!isApproved) {
        return await erc1155Contract.setApprovalForAll(JUNKYARD_ADDRESS, true)
      } else {
        return null
      }
    } else if (await erc165Contract.supportsInterface(ERC721InterfaceId)) {
      const erc721Contract = new ethers.Contract(address, erc721Abi, library.getSigner())
      const isApproved = await erc721Contract.isApprovedForAll(account, JUNKYARD_ADDRESS)
      if (!isApproved) {
        return await erc721Contract.setApprovalForAll(JUNKYARD_ADDRESS, true)
      } else {
        return null
      }
    } else {
      throw ContractNotNFTError(address)
    }
  }

  const triggerTransactions = async () => {
    // clear out state on button click
    setPendingApprovals({})
    setLaunchingApprovalRequests(false)

    try {
      const [erc721Junkgroup, erc1155JunkGroup, addresses] = junkGroupsFromSelectedNFTs(nfts)

      // send transactions to get approval for all contracts
      setLaunchingApprovalRequests(true)
      const errors = []
      await Promise.all(
        addresses.map(async address => {
          try {
            const tx = await getApproval(address)
            if (tx === null) {
              return
            }

            setPendingApprovals(prevState => ({
              ...prevState,
              [tx.hash]: tx
            }))

            await tx.wait()
            setPendingApprovals(prevState => ({
              ...prevState,
              [tx.hash]: null
            }))
          } catch (e) {
            errors.push(e)
          }
        })
      )
      setLaunchingApprovalRequests(false)

      if (errors.length > 0) {
        setTransactionFailedReason(
          errors
            .map(err => {
              if (err instanceof ContractNotNFTError) {
                return `Contract ${shortenAddress(err.message)} is not a valid NFT contract`
              } else if (err.message?.includes("user rejected transaction")) {
                return `You have rejected our approval request`
              } else {
                console.error(err)
                return "Approval request transaction failed"
              }
            })
            .join("; ")
        )
        return
      }

      // setting this here so this modal doesn't revert to original briefly
      setPendingJunkTx(true)

      const junkyardContract = new ethers.Contract(
        JUNKYARD_ADDRESS,
        junkyardAbi,
        library.getSigner()
      )

      // junk NFTs
      const tx = await junkyardContract.junkNFTs(
        erc721Junkgroup,
        erc1155JunkGroup,
        localStorage.getItem("ref") ?? "",
        {
          value: totalFee
        }
      )
      setPendingJunkTxHash(tx.hash)
      await tx.wait()

      navigate("/success", {state: {txHash: tx.hash, estimatedLosses}})
      setPendingJunkTx(false)
      setPendingJunkTxHash("")
    } catch (e) {
      console.error(e)
      setTransactionFailedReason(`Failed to junk NFTs, reason: ${e.message}`)
    }
  }

  const modalContents = transactionFailedReason ? (
    <Stack m="xl" align="center">
      <Title color="indianred">Error!</Title>
      <Text color="indianred">{transactionFailedReason}</Text>
    </Stack>
  ) : launchingApprovalRequests ? (
    <Stack m="xl" align="center">
      <Loader />
      <Text color="dimmed">Awaiting your approval for approval requests...</Text>
    </Stack>
  ) : Object.keys(pendingApprovals).filter(hash => !!pendingApprovals[hash]).length !== 0 ? (
    <Stack align="center">
      <Loader />
      <Text color="dimmed">Waiting on the following contracts to be approved:</Text>
      {Object.values(pendingApprovals)
        .filter(tx => !!tx)
        .map(tx => (
          <Anchor
            color="dimmed"
            target="_blank"
            href={`${ETHERSCAN_URL}/tx/` + tx.hash}
            key={tx.hash}
          >
            {shortenAddress(tx.to)}
          </Anchor>
        ))}
    </Stack>
  ) : pendingJunkTx ? (
    <Stack m="xl" align="center">
      <Loader />
      <Text color="dimmed">Waiting for the transaction to complete...</Text>
      {pendingJunkTxHash && (
        <Anchor color="dimmed" target="_blank" href={`${ETHERSCAN_URL}/tx/` + pendingJunkTxHash}>
          Click here to view your transaction
        </Anchor>
      )}
    </Stack>
  ) : (
    <Stack align="center">
      <Title>Begin your transaction!</Title>
      <Text sx={{textAlign: "center"}} mb="sm">
        In order to complete your sale,{" "}
        <Text weight={600} component="span">
          you will need to approve a separate Metamask transaction for each individual collection
          you’ve chosen. Then, a final transaction will ask you to confirm.
        </Text>{" "}
        If you deny any transfer approvals, the entire sale process will stop. However, any
        transactions you already approved may still take effect. To begin, click on the button
        below.
      </Text>
      <Button size="lg" onClick={triggerTransactions}>
        Approve and sell
      </Button>
    </Stack>
  )

  return (
    <Modal
      centered
      withCloseButton={false}
      opened={opened}
      onClose={() => setOpened(false)}
      closeOnClickOutside={modalAllowedToClose}
      closeOnEscape={modalAllowedToClose}
    >
      <Center>{modalContents}</Center>
    </Modal>
  )
}
