import React from "react"
import {useEthers} from "@usedapp/core"
import Web3Modal from "web3modal"
import WalletConnectProvider from "@walletconnect/web3-provider"
import CoinbaseWalletSDK from "@coinbase/wallet-sdk"
import {Button} from "@mantine/core"
import {INFURA_ID} from "../../constants"

// TODO figure out if error handling is necessary for the modal
export default function Web3ModalButton({text = "Connect", size = "lg"}) {
  const {activate} = useEthers()

  const activateProvider = async () => {
    const providerOptions = {
      injected: {
        display: {
          name: "Metamask",
          description: "Connect with the provider in your Browser"
        },
        package: null
      },
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: INFURA_ID
        }
      },
      coinbasewallet: {
        package: CoinbaseWalletSDK, // Required
        options: {
          appName: "NFT Junkyard", // Required
          infuraId: INFURA_ID // Required
        }
      }
    }

    const web3Modal = new Web3Modal({
      providerOptions
    })
    try {
      const provider = await web3Modal.connect()
      await activate(provider)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Button size={size} onClick={() => activateProvider()}>
      {text}
    </Button>
  )
}
