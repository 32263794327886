import React from "react"
import {Link} from "react-router-dom"
import {Container, Stack, Title, Text} from "@mantine/core"
import {Helmet} from "react-helmet"
import BlogHeading from "../../components/blog/BlogHeading"
import {BLOG_TITLE_HOW_ARE_NFTS_TAXED} from "../../constants"

export default function HowAreNFTsTaxed() {
  return (
    <>
      <Helmet>
        <title>How are NFTs taxed? 2022 NFT Taxes Explained | NFT Tax Saver</title>
        <meta
          name="description"
          content="Wondering how NFTs are taxed? Do you owe taxes on NFTs? Our 2022 NFT Tax Guide explains all."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Container>
        <Stack>
          <BlogHeading
            title={BLOG_TITLE_HOW_ARE_NFTS_TAXED}
            author={{name: "The NFT Tax Saver Team"}}
            date={"October 25, 2022"}
          />
          <Text>
            During the raging bull market of 2021, NFTs finally began to gain popularity among a
            niche community of investors, traders, and degens. As animal spirits cooled off in 2022,
            many have started to think about the tax implications of buying, selling, and holding
            NFTs. Due to the innovative nature of NFTs, there doesn’t yet exist reputable guidance
            on how NFTs are taxed.
          </Text>
          <Text>
            But worry not – in this guide, our team here at NFT Tax Saver will explain how NFTs are
            taxed and how to prepare for the upcoming tax season.
          </Text>
          <Title order={2}>What are NFTs?</Title>
          <Text>
            NFTs are a type of token on the blockchain. NFT stands for “non-fungible token.” The
            word “non-fungible” refers to the fact that within an NFT collection, each individual
            token is unique. For example, when you buy a CryptoPunk NFT, your NFT token is going to
            be different from the other 9,999 CryptoPunk NFTs in the collection. This is possible
            because when NFTs are coded on the blockchain, they are able to store unique metadata in
            the form of images, properties, and more.
          </Text>
          <Text>
            Contrast NFTs with other blockchain-enabled products like cryptocurrencies. When you buy
            a bitcoin, it is not different from any of the other 21 million bitcoin tokens out
            there. You could exchange your bitcoin for someone else’s bitcoin, and as long as you
            both had the same amount of bitcoin as when you started, you’d be indifferent. But if
            you exchanged your Cool Cat NFT token for someone else’s Cool Cat NFT, you might not be
            indifferent at all – your Cool Cat may possess a certain combination of unique qualities
            (facial expression, hat, eye shape) that make you prefer it over any other Cool Cat NFT.
          </Text>
          <Text>
            The “non-fungible” aspect of NFTs is what excites builders, investors, and speculators.
            NFTs ultimately enable digital ownership of unique tokens on the blockchain, which has
            the potential to transform a number of industries like community building, gaming,
            music, art, and more.
          </Text>
          <Title order={2}>How do NFT taxes work?</Title>
          <Text>
            NFTs are taxed every time you sell them and realize a gain or loss. When you buy an NFT,
            the amount you paid is called your “cost-basis.” So, if you spend $1000 on an NFT, your
            cost-basis is $1000. If someone airdropped or gifted you an NFT, your cost-basis is $0,
            since you didn’t pay anything for it.
          </Text>
          <Text>
            When your NFT increases or decreases in value relative to your cost-basis but you
            haven’t yet sold, you are considered to have an “unrealized gain” or “unrealized loss.”
            So, if you paid $300 for an NFT that is now worth $1000, you have an “unrealized gain”
            of $700. Similarly, if you paid $300 for an NFT that has fallen in value to $100, you
            have an “unrealized loss” of $200.
          </Text>
          <Text>
            Regardless, you are not taxed on unrealized gains or losses. It doesn’t matter if you
            are sitting on unrealized gains of millions of dollars. If you don’t sell, you don’t
            incur a taxable event.
          </Text>
          <Text>
            However, you ARE taxed every time you “realize” a gain or loss, which typically happens
            when you sell an NFT for more or less than your cost-basis.
          </Text>
          <Title order={2}>Are NFTs taxed as income?</Title>
          <Text>
            NFTs are taxed as property, which are subject to capital gains tax. In other words, your
            tax obligation is calculated based on the difference between your cost-basis (the amount
            you originally paid for the NFT) and your sale price (the amount you sold your NFT for).
          </Text>
          <Text>
            For example, if you bought an NFT for $1000 and sold it for $2500, your cost-basis is
            $1000, your sale price is $2500, and therefore your capital gain is $1500. You will owe
            taxes on that $1500.
          </Text>
          <Text>
            How capital gains are taxed for you depends on a number of factors, including how long
            your held the NFT before you sold it, the tax code where you live, your income, and
            more. We recommend doing research on how capital gains are taxed in your situation,
            since capital gains via NFTs are not different from capital gains via other assets.
          </Text>
          <Title order={2}>
            Are NFTs considered long-term capital gains or short-term capital gains?
          </Title>
          <Text>
            Whether NFTs are regarded as short-term capital gains or long-term capital gains depends
            on how long you held your NFT before selling it. If you bought your NFT and sold it in
            less than 1 year, then it is considered short-term capital gains. If you held your NFT
            for longer than 1 year before selling it, then it is regarded as long-term capital
            gains.
          </Text>
          <Text>
            The benefit of long-term capital gains is that typically long-term capital gains are
            taxed at lower rates relative to short-term capital gains. Governments do this to
            encourage and incentivize investors to think long-term.
          </Text>
          <Text>
            Due to the hype-driven nature of NFTs and the fact that most people who buy and sell
            NFTs are looking to “flip” them on short-time horizons, most NFT trades will probably
            fall into the category of short-term capital gains. If you are one of the rare
            diamond-handed individuals who have held onto an NFT for longer than a year profitably,
            congratulations – your reward (besides clout) is likely a lower tax rate due to
            long-term capital gains.
          </Text>
          <Title order={2}>Are NFTs taxed as collectibles? </Title>
          <Text>
            It is true that there are people who believe that NFTs should be taxed as collectibles.
            Given some NFT collections’ similarities to physical collectibles like trading cards and
            Beanie Babies, the argument can be made that NFTs are merely digital versions of
            traditional collectibles. On the other hand, it can be argued that it’s not a perfect
            comparison, as NFTs are otherwise very similar to cryptocurrencies – they’re ultimately
            tokens on a blockchain.
          </Text>
          <Text>
            Currently, the IRS has not given clarity around whether NFTs are taxed as collectibles.
            Until they give clarity, investors are merely expected to give a good faith
            interpretation when deciding how to pay their taxes. Obviously, we recommend discussing
            with your accountant.
          </Text>
          <Text>
            If you are considering recognizing your NFTs are collectibles, the main difference,
            practically speaking, only comes into play if you have held your NFT for more than 1
            year. This is because the long-term capital gains tax for collectibles is often higher
            than the long-term capital gains tax for cryptocurrencies. If you have held your NFT for
            less than 1 year, then your transaction would fall under short-term capital gains, in
            which case your tax rate would be the same whether it was a collectible or not – it
            would be taxed at your short-term capital gains rate, which is usually the same as your
            income tax rate.
          </Text>
          <Title order={2}>How is gas used for NFT transactions taxed?</Title>
          <Text>
            As of 2022, the IRS has not given concrete clarity on how to interpret gas used for
            buying and selling NFTs. One common interpretation, however, is that gas can be
            considered as part of your cost-basis. For example, if you bought an NFT for $100, and
            gas cost $5, then your cost-basis would be $105. When you sell your NFT for $250, and
            gas cost $6, then your cost-basis would increase by $6 to $111. Since you sold for $250
            and your cost-basis was $111, your total profit would be $139.
          </Text>
          <Text>
            In other words, gas used for NFT transactions tends to lower your tax obligation, since
            it can be considered to increase your cost-basis (which, all else equal, means your
            realized profit is lower).
          </Text>
          <Title order={2}>Am I taxed on NFT airdrops? </Title>
          <Text>
            Because airdrops are often considered income by the IRS, getting an NFT airdropped to
            you would technically trigger a taxable event since it is effectively adding to your
            total profit for the year. Think about it – you are effectively 1 NFT richer after the
            airdrop occurs.
          </Text>
          <Text>
            However, the IRS is less clear on how much to value the airdrop. It’s often not clear
            exactly how much an airdropped NFT is worth at the moment of the airdrop, especially
            because usually no trading has occurred yet. Until more clarity is given, investors are
            expected to give their best faith interpretation of the value and increase their capital
            gains accordingly.
          </Text>
          <Text>
            Something to remember is that selling your airdropped NFT will result in another taxable
            event, since you are realizing a gain in value between how much you sold it for and how
            much it was worth when you received the NFT.
          </Text>
          <Title order={3}>Airdropped NFT Tax Example – Cost-basis included</Title>
          <Text>
            Let’s say Daniel gets airdropped an NFT. It’s a little unclear how much it’s worth, but
            Daniel concludes that it probably is worth around $1000. If so, Daniel owes taxes on
            that $1000 as though it were income.
          </Text>
          <Text>
            A week later, Daniel’s NFT has risen in value to $2500. He decides to sell, realizing a
            gain of $1500. Daniel owes taxes on the $1500 as well (usually in the form of short-term
            capital gains).
          </Text>
          <Title order={3}>Airdropped NFT Tax Example – No cost-basis included</Title>
          <Text>
            Let’s say Daniel gets airdropped that same NFT, but this time, he concludes that it’s
            worth $0. He technically would owe taxes on the airdrop, but since its value is $0, his
            tax situation doesn’t change.
          </Text>
          <Text>
            A week later, Daniel’s NFT has risen in value to $2500. He decides to sell and realizes
            a gain of $2500. Daniel owes taxes on the $2500 (also in the form of short-term capital
            gains).
          </Text>
          <Text>
            What you notice in both examples is that, in the end, regardless of how much Daniel
            valued his airdropped NFT at originally, he still ends up paying the taxes on the final
            value he sold it for ($2500). This is because he ultimately sold it in the same calendar
            year, meaning everything was classified under short-term capital gains.
          </Text>
          <Text>
            Where it gets trickier is if Daniel waits a year to sell his airdropped NFT. Then, the
            original value of his airdropped NFT will make a difference in his total tax obligation,
            as he will pay the short-term capital gains tax rate on the value of his NFT at airdrop
            and long-term capital gains on the value of his NFT at final sale.
          </Text>
          <Title order={2}>Will I be taxed on an NFT gift?</Title>
          <Text>
            Although there is no strict clarity from the IRS, it is a reasonable interpretation to
            assume that NFT gifts are taxed the same as other gifts. In 2022, if the NFT you were
            gifted is worth less than $16,000, it won’t be taxed until you sell the NFT. If it’s
            worth greater than $16,000, then it will be subject to gift taxes. We would recommend
            checking with your accountant to understand your exact situation.
          </Text>
          <Title order={2}>How are NFT taxes calculated?</Title>
          <Text>NFTs are taxed as property, meaning they are subject to capital gains taxes.</Text>
          <Text>
            Assets that are taxed as capital gains can either be considered short-term capital gains
            or long-term capital gains — if you’ve held your asset for less than 1 year before
            selling, then you are subject to short-term capital gains; if you’ve held it for longer
            than 1 year, then you are subject to long-term capital gains.
          </Text>
          <Text>
            Typically, long-term capital gains will have a lower tax rate than short-term capital
            gains. Short-term capital gains are usually taxed at your income tax rate, while
            long-term capital gains are taxed at a fixed rate.
          </Text>
          <Text>
            If you’ve held your NFTs for less than a year, a simple way to understand how much you
            will owe in NFT taxes is to take the total realized profit and multiply it by your
            income tax rate.
          </Text>
          <Text>
            If you’ve held your NFTs for longer than a year, then you should take the total realized
            profit and multiply it by the long-term capital gains rate where you live.
          </Text>
          <Text>
            Ultimately, however, NFT taxes are calculated based on a number of factors, including
            your income tax bracket, household status, geography, and more. Be sure to talk to your
            accountant about your unique situation.
          </Text>
          <Title order={2}>How much will I pay in NFT taxes?</Title>
          <Text>
            The amount you specifically will pay in NFT taxes will differ depending on your unique
            tax situation. However, below we will provide a method to calculate your NFT taxes. It
            is easiest to do this with a spreadsheet.
          </Text>
          <Title order={3}>How to Calculate How Much You Owe in NFT Taxes</Title>
          <Text>
            In the first column (“NFT Name”), list the names of all the NFTs you bought and sold
            this year.
          </Text>
          <Text>
            In the second column (“Cost-Basis”), record the amount you originally paid for each NFT
            you purchased in USD. If you paid for your NFT in ETH or SOL, make sure to convert your
            ETH or SOL into the equivalent dollars based on the prices. Note that you can add in the
            cost of gas to this figure as well.
          </Text>
          <Text>
            In the third column (“Sell Price”), record the amount you sold each NFT for in USD. If
            you did NOT sell the NFT yet, you don’t need to pay taxes on it, since taxes are only
            owed when you actually sell an NFT. You should remove unsold NFTs from this spreadsheet.
          </Text>
          <Text>
            In the fourth column (“Short vs Long”), record whether the transaction should be
            considered short-term capital gains or long-term capital gains. If the time between the
            purchase and sale of the NFT was less than 1 year, record “Short.” If it was longer than
            1 year, record “Long.”
          </Text>
          <Text>
            In the fifth column (“Realized Gain”), take the difference between the “Sell Price” and
            the “Cost-Basis.” This is your realized profit (or loss!) and is what you will owe taxes
            on.
          </Text>
          <Text>
            Now, you can go ahead and add up all of your Short-Term Realized Gains/Losses and all of
            your Long-Term Realized Gains/Losses. Make sure to keep the two totals separate.
          </Text>
          <Text>
            Multiply your Short-Term Realized Gains/Losses by your Short-Term Capital Gains Tax
            Rate. You can find your Short-Term Capital Gains Tax Rate by searching on Google,
            “short-term capital gains tax rate [where you live].” Chances are, your Short-Term
            Capital Gains Tax Rate will be the same as your income tax rate, but not always. Don’t
            forget to factor in both Federal and State Tax if you live in the US.
          </Text>
          <Text>
            Next, multiply your Long-Term Realized Gains/Losses by your Long-Term Capital Gains Tax
            Rate. You can find your Long-Term Capital Gains Tax Rate by searching, “long-term
            capital gains tax rate [where you live].”
          </Text>
          <Text>
            And that’s it! Again, be sure to talk to your accountant for your unique situation. But
            this might give you an idea generally of how the process works. Also, this process is
            essentially what crypto tax softwares like Koinly, Zenledger, and Coinledger help to
            automate. We aren’t sponsored by them (and have not really tried their products
            ourselves), but we can see the value of having a tool automatically calculate your
            gains/losses and classify them into short vs long term, especially if you trade a lot.
          </Text>
          <Title order={3}>NFT Tax Example — How much will I pay?</Title>
          <Text>
            Let’s say Alice bought three NFTs: NFT1, NFT2, and NFT3. She bought NFT1 for $400, NFT2
            for $1000, and NFT3 for $1500.
          </Text>
          <Text>She has not sold NFT1 yet.</Text>
          <Text>She sold NFT2 for $1300 within the same year she bought it.</Text>
          <Text>She sold NFT3 for $2000 after holding it for 2 years.</Text>
          <Text>
            On NFT1, Alice will pay no taxes, since she is only taxed when she sells, not when she
            buys.
          </Text>
          <Text>
            On NFT2, Alice will pay taxes on the realized gain, which is $300. Since she held it for
            less than one year, she will be taxed at her short-term capital gains rate. Based on
            where she lives and her tax situation, she understands her short-term capital gains rate
            to be the same as her income-tax rate — roughly 35%. She owes 35% in taxes on her gain
            of $300, which is roughly $105. She pays $105 in taxes.
          </Text>
          <Text>
            On NFT3, Alice will pay taxes on the realized gain, which is $500. Since she held it for
            longer than a year, she will be taxed at her long-term capital gains rate. She talks to
            her accountant and realizes that to be roughly 22.5%. She owes $112.50 in taxes.
          </Text>
          <Title order={2}>Do I pay taxes on NFT losses?</Title>
          <Text>
            In short, no. You only pay taxes on realized gains. If you have a realized loss, you
            will not pay taxes. Better yet, the IRS allows you to roll forward up to $3000 of losses
            to future years as a way to reduce your taxes. So for example, if you experienced
            realized losses of $2000 this year, then next year, when you have $5000 in realized
            gains, you can actually pay taxes on just $5000 - $2000 = $3000 of realized gains
            instead.
          </Text>
          <Text>
            Another thing to note is that if you have unrealized losses on NFTs, you should consider
            selling your NFTs to “realize your losses” and decrease the total amount of realized
            gain you owe taxes on. This process is sometimes referred to as “NFT Tax Loss
            Harvesting” and is a great strategy to reduce your tax burden legally.
          </Text>
          <Title order={3}>NFT Tax Example — Using Losses to Offset Gains</Title>
          <Text>
            Let’s say Alice has $3000 in realized gains so far. However, she realizes that an NFT
            she bought earlier this year for $2000 is now worth $200 — a 90% drop. Therefore, she is
            also sitting on an $1800 unrealized loss. She doesn’t feel confident that the NFT will
            ever recover its value. Instead of holding the NFT, she can choose to sell it and
            “realize” a loss of $1800. Now, instead of having to pay taxes on $3000 of realized
            gains, she only has to pay taxes on $1200.
          </Text>
          <Title order={2}>How are NFT taxes calculated for Opensea and Magiceden?</Title>
          <Text>
            The fact that you traded your NFTs on Opensea or MagicEden doesn’t change the fact that
            you’ve traded NFTs generally. Regardless of whether you traded NFTs on Opensea,
            MagicEden, or another platform, the same rules outlined in this post apply.
          </Text>
          <Title order={2}>NFT Tax Regulation</Title>
          <Text>
            Currently, the IRS has not given clear guidance on how NFTs are taxed. While this could
            change in the future, most accountants default to treating NFTs like cryptocurrencies,
            which generally are taxed as property.
          </Text>
          <Text>
            The most important thing is that taxpayers give a good-faith effort to pay their taxes.
            As long as you have clear, consistent reasoning for how you choose to recognize and pay
            your taxes, you should be ok. Talk to your accountant to be sure.
          </Text>
          <Title order={2}>Do I have to pay taxes on NFTs?</Title>
          <Text>
            In short, yes. If you realize gains or losses when trading NFT, you will need to pay
            taxes. If you do not, you will be committing tax evasion. Read more in our other guide,{" "}
            <Link to="/blog/do-i-need-to-pay-taxes-on-nfts">Do I have to pay taxes on NFTs?</Link>
          </Text>
        </Stack>
      </Container>
    </>
  )
}
