import React from "react"
import {Link} from "react-router-dom"
import {Helmet} from "react-helmet"
import {Container, Stack, Title, Text} from "@mantine/core"

export default function About() {
  return (
    <>
      <Helmet>
        <title>About | NFT Tax Saver</title>
      </Helmet>
      <Container>
        <Stack>
          <Title mb="lg">About Us</Title>
          <Text>
            NFT Tax Saver is the easiest way to save on taxes by selling your illiquid NFTs at a
            loss.
          </Text>
          <Text>
            The idea for NFT Tax Saver was born when our founders realized they had purchased a ton
            of NFTs that were now worth significantly less than what they originally paid for them.
            Sound familiar?
          </Text>
          <Text>
            Typically, with liquid assets like stocks, when you make a bad trade, you can simply
            sell and realize your losses, thereby decreasing your total profits (and tax bill).
          </Text>
          <Text>
            But with NFTs, selling is more complicated – you have to wait until someone else is
            available to buy your exact NFT from you. For many collections, interest can be so low
            that it becomes near impossible to actually sell your NFT. You’re stuck with inventory
            that you know is worthless, but you are unable to realize your losses for tax purposes
            (losses are only realized when a sale actually happens).
          </Text>
          <Text>
            <Text component="span" weight={700}>
              NFT Tax Saver fixes this.{" "}
            </Text>
            Our dapp allows you to sell any NFT to us, and we’ll buy it off of you for 1 gwei, or a
            tiny fraction of a USD cent.* You’ve now officially realized a loss on this sale, which
            means you will report a lower profit gain (or even a loss) on your tax documents.
            Usually, this means you’ll pay less in taxes that year.**
          </Text>
          <Text>
            What’s in it for us? Well, two things. First, we’re excited at the prospect that one day
            some of these NFTs might be worth something. Sure, your backward-facing angry aardvark
            with sunglasses and questionable teeth may not be worth anything now, but we like to
            think of ourselves as a pawn shop for NFTs. One man’s trash is another man’s treasure,
            even on the blockchain.
          </Text>
          <Text>
            Second, we like building cool stuff. It was a lot of fun to create NFT Tax Saver, and
            we’re always shipping improvements. We intend to create more useful products in the
            future, all aimed towards making life easier for crypto participants.
          </Text>
          <Text>
            We’ve tried to make the user experience as intuitive as possible, but if you want
            instructions on how to use NFT Tax Saver,{" "}
            <Link to="/how-it-works">here’s a guide on How It Works.</Link>
          </Text>
          <Text size="sm" italic>
            *If you want the exact value we’ll buy it for, 1 gwei is roughly 0.00000000114 ETH. So
            if 1 ETH is worth $1k, then 1 gwei is equal to roughly 1/100,000th of a cent in USD.
            Which is still more than you’ve made in your entire trading career. Ohhh!!!!!!
          </Text>
          <Text size="sm" italic>
            ** Disclaimer: NFT Tax Saver does not make any guarantees of tax loss savings. Also,
            nothing we say should be construed as tax or legal advice. Always consult with your
            accountant or lawyer.
          </Text>
        </Stack>
      </Container>
    </>
  )
}
