import {AppShell} from "@mantine/core"
import React, {useEffect, useMemo, useState} from "react"
import {Route, Routes, useLocation, useNavigate} from "react-router-dom"
import Footer from "./components/common/Footer"
import NavBar from "./components/common/NavBar"
import About from "./pages/About"
import Blog from "./pages/Blog"
import DoINeedToPayTaxes from "./pages/blog-pages/DoINeedToPayTaxes"
import HowAreNFTsTaxed from "./pages/blog-pages/HowAreNFTsTaxed"
import HowToLossHarvest from "./pages/blog-pages/HowToLostHarvest"
import FAQ from "./pages/FAQ"
import Home from "./pages/Home"
import HowItWorks from "./pages/HowItWorks"
import NoMatch from "./pages/NoMatch"
import SaleConfirmation from "./pages/SaleConfirmation"
import SaleSuccess from "./pages/SaleSuccess"
import Referral from "./pages/Referral"
import {ETHERSCAN_URL, JUNKYARD_ADDRESS} from "./constants"
import {IconX} from "@tabler/icons"

const MENU = [
  {
    link: "/about",
    label: "About"
  },
  {
    link: "/how-it-works",
    label: "How it works"
  },
  {
    link: "/faq",
    label: "FAQ"
  },
  {
    link: "/blog",
    label: "Blog"
  },
  {
    link: "/referral",
    label: "Referral"
  }
]

function App() {
  const {search, pathname} = useLocation()
  const navigate = useNavigate()
  const [showMobileBanner, setShowMobileBanner] = useState(true)

  const query = useMemo(() => new URLSearchParams(search), [search])
  useEffect(() => {
    const ref = query.get("ref")
    if (ref) {
      localStorage.setItem("ref", ref)
      navigate(pathname)
    }
  }, [query, navigate, pathname])

  return (
    <AppShell
      padding="xs"
      header={
        <>
          <NavBar links={MENU} />
          {showMobileBanner && (
            <div className="mobile-banner">
              <p>
                Note: our UI is not yet optimized for mobile devices. We recommend trying it on
                Desktop for a cleaner experience.
              </p>
              <div
                className="close"
                onClick={() => {
                  setShowMobileBanner(false)
                }}
              >
                <IconX />
              </div>
            </div>
          )}
        </>
      }
      footer={
        <Footer
          links={[
            ...MENU,
            {
              link: `${ETHERSCAN_URL}/address/${JUNKYARD_ADDRESS}#code`,
              label: "Contract",
              external: true
            }
          ]}
        />
      }
    >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/confirmation" element={<SaleConfirmation />} />
        <Route path="/success" element={<SaleSuccess />} />
        <Route path="/referral" element={<Referral />} />
        {/* Hard coding blog because there will only ever be 4ish pages. */}
        <Route path="/blog/do-i-need-to-pay-taxes-on-nfts" element={<DoINeedToPayTaxes />} />
        <Route path="/blog/how-to-tax-loss-harvest-nfts" element={<HowToLossHarvest />} />
        <Route path="/blog/how-are-nfts-taxed" element={<HowAreNFTsTaxed />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </AppShell>
  )
}

export default App
