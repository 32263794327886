import {createStyles, Title, Text, Button, Container} from "@mantine/core"
import {Link} from "react-router-dom"
import {Dots} from "./Dots"

const useStyles = createStyles(theme => ({
  wrapper: {
    paddingTop: 80,
    // paddingBottom: 80,

    "@media (max-width: 755px)": {
      paddingTop: 80
      // paddingBottom: 60,
    }
  },

  inner: {
    position: "relative",
    zIndex: 1
  },

  dots: {
    position: "absolute",
    color: theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1],

    "@media (max-width: 755px)": {
      display: "none"
    }
  },

  dotsLeft: {
    left: 0,
    top: 0
  },

  title: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: 40,
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,

    "@media (max-width: 520px)": {
      fontSize: 28,
      textAlign: "left"
    }
  },

  description: {
    textAlign: "center",

    "@media (max-width: 520px)": {
      textAlign: "left",
      fontSize: theme.fontSizes.md
    }
  },

  controls: {
    marginTop: theme.spacing.lg,
    display: "flex",
    justifyContent: "center",

    "@media (max-width: 520px)": {
      flexDirection: "column"
    }
  },

  control: {
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
      marginBottom: theme.spacing.md
    }
  }
}))

export default function HomeHero({handleClick}) {
  const {classes} = useStyles()

  return (
    <Container className={classes.wrapper} size={1400}>
      <Dots className={classes.dots} style={{left: 0, top: 0}} />
      <Dots className={classes.dots} style={{left: 60, top: 0}} />
      <Dots className={classes.dots} style={{left: 0, top: 140}} />
      <Dots className={classes.dots} style={{right: 0, top: 120}} />

      <div className={classes.inner}>
        <Title className={classes.title}>Save on taxes by selling your illiquid NFTs</Title>

        <Container p={0} size={600}>
          <Text size="lg" className={classes.description}>
            Can't find anyone to buy your NFTs and lock in your losses? Sell them to us instantly
            and reduce your tax bill.
          </Text>
        </Container>

        <div className={classes.controls}>
          <Button mr="lg" radius="md" className={classes.control} onClick={handleClick}>
            Get started
          </Button>
          <Button radius="md" variant="default" component={Link} to="/about">
            Learn more
          </Button>
        </div>
      </div>
    </Container>
  )
}
