import {Grid, ScrollArea, Text} from "@mantine/core"
import React from "react"
import {getContractLabel, getMediaComponent, getNFTKey} from "../../utils/nftUtils"

export default function SelectedNFTsScroll({nfts, textSize}) {
  return (
    <ScrollArea.Autosize maxHeight={530}>
      {nfts.map(nftDatumWithCount => {
        const nftDatum = nftDatumWithCount[0]
        const count = nftDatumWithCount[1]
        const media = getMediaComponent(nftDatum, 80)
        return (
          <Grid key={getNFTKey(nftDatum)} align="center" m="0" p="0">
            <Grid.Col span={4}>
              <div style={{marginLeft: "-8px"}}>{media}</div>
            </Grid.Col>
            <Grid.Col span={8} offset={0}>
              <Text size={textSize} weight={500} align="right" style={{marginRight: "-8px"}}>
                {nftDatum.title} {nftDatum["tokenType"] === "ERC1155" && `(x${count})`}
              </Text>
              <Text size="xs" align="right" style={{marginRight: "-8px"}}>
                {getContractLabel(nftDatum)}
              </Text>
            </Grid.Col>
          </Grid>
        )
      })}
    </ScrollArea.Autosize>
  )
}
