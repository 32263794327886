import {Link} from "react-router-dom"
import {createStyles, Container, Group, Text, useMantineTheme} from "@mantine/core"

const useStyles = createStyles(theme => ({
  footer: {
    marginTop: theme.spacing.lg,
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    backgroundColor: "#020E44",
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column"
    }
  },

  links: {
    [theme.fn.smallerThan("xs")]: {
      marginTop: theme.spacing.md
    }
  }
}))

export default function Footer({links}) {
  const {classes} = useStyles()
  const theme = useMantineTheme()
  const items = links.map(link =>
    link.external ? (
      <a
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: "none",
          color: theme.colors.gray[6]
        }}
        key={link.label}
        href={link.link}
      >
        {link.label}
      </a>
    ) : (
      <Link
        style={{
          textDecoration: "none",
          color: theme.colors.gray[6]
        }}
        key={link.label}
        to={link.link}
      >
        {link.label}
      </Link>
    )
  )

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <Text color="dimmed" size="sm">
          © 2022 NFT Tax Saver. All rights reserved.
        </Text>
        <Group className={classes.links}>{items}</Group>
      </Container>
    </div>
  )
}
