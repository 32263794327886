import {Container, Stack, Title} from "@mantine/core"
import React from "react"
import ReferralLink from "../components/common/ReferralLink"

export default function Referral() {
  return (
    <Container py="xl">
      <Stack>
        <Title>Help spread the word!</Title>
      </Stack>
      <ReferralLink />
    </Container>
  )
}
