import React from "react"
import {Link} from "react-router-dom"
import {Container, Stack, Title, Text, createStyles} from "@mantine/core"
import {
  BLOG_TITLE_DO_I_NEED_TO_PAY,
  BLOG_TITLE_HOW_TO_HARVEST,
  BLOG_TITLE_HOW_ARE_NFTS_TAXED
} from "../constants"
import {Helmet} from "react-helmet"

const useStyles = createStyles(theme => ({
  link: {
    textDecoration: "none",
    color: "black",

    "&:hover": {
      textDecoration: "underline"
    }
  }
}))

export default function Blog() {
  const {classes} = useStyles()

  return (
    <>
      <Helmet>
        <title>Blog | NFT Tax Saver</title>
      </Helmet>
      <Container>
        <Stack>
          <Title mb="lg">Blog</Title>
          <Stack mb="md" spacing="xs">
            <Link className={classes.link} to="/blog/do-i-need-to-pay-taxes-on-nfts">
              <Title order={2}>{BLOG_TITLE_DO_I_NEED_TO_PAY}</Title>
            </Link>
            <Text size="sm" color="dimmed">
              October 20, 2022
            </Text>
            <Text lineClamp={2} italic>
              If you’ve purchased or sold NFTs this year, you may be wondering whether there are tax
              implications. In short, yes — you will likely need to pay taxes on your NFT activity,
              or at least do a few calculations to make sure that you don’t owe any. In this guide,
              we’ll explain everything you need to know about NFT taxes.
            </Text>
          </Stack>
          <Stack mb="md" c>
            <Link to="/blog/how-to-tax-loss-harvest-nfts" className={classes.link}>
              <Title order={2}>{BLOG_TITLE_HOW_TO_HARVEST}</Title>
            </Link>
            <Text size="sm" color="dimmed">
              October 23, 2022
            </Text>
            <Text lineClamp={2} italic>
              Over the past few years, many retail and institutional investors have begun to buy and
              sell NFTs as part of their investment strategies. Although the first ever NFT was
              minted back in 2014, most mainstream investors had never heard of NFTs until 2021 when
              projects like CryptoPunks, Bored Ape Yacht Club, and Axie Infinity surged in
              popularity and value. These marquee collections ended up kicking off the “NFT Bull
              Run” of 2021 and 2022, leading to an explosion in the number of NFT launches promising
              advancements in art, gaming, and community-building.
            </Text>
          </Stack>
          <Stack spacing="xs">
            <Link to="/blog/how-are-nfts-taxed" className={classes.link}>
              <Title order={2}>{BLOG_TITLE_HOW_ARE_NFTS_TAXED}</Title>
            </Link>
            <Text size="sm" color="dimmed">
              October 25, 2022
            </Text>
            <Text lineClamp={2} italic>
              During the raging bull market of 2021, NFTs finally began to gain popularity among a
              niche community of investors, traders, and degens. As animal spirits cooled off in
              2022, many have started to think about the tax implications of buying, selling, and
              holding NFTs. Due to the innovative nature of NFTs, there doesn’t yet exist reputable
              guidance on how NFTs are taxed.
            </Text>
          </Stack>
        </Stack>
      </Container>
    </>
  )
}
