import {
  Button,
  CloseButton,
  Grid,
  Group,
  Loader,
  Modal,
  NumberInput,
  Stack,
  Table,
  Text,
  ThemeIcon,
  Tooltip,
  useMantineTheme
} from "@mantine/core"
import {useTimeout} from "@mantine/hooks"
import {IconAlertCircle, IconAlertTriangle} from "@tabler/icons"
import {useEthers} from "@usedapp/core"
import React, {useEffect, useState} from "react"
import {getUniqueContractAddressesFromNFTs, sortLosses} from "../../utils/junkyardUtils"
import {getLossEstimates, getMediaComponent} from "../../utils/nftUtils"

/**
 * This is the component for the modal that pops up when estimating losses. The modal supports making changes to loss numbers or deleting selected NFTs,
 * but any changes must be saved.
 */
export default function SavingsEstimateModal({
  opened,
  setOpened,
  selectedNFTs,
  setSelectedNFTs,
  setEstimatedTokens,
  setEstimatedLosses
}) {
  const {account} = useEthers()
  const theme = useMantineTheme()
  const [saveButtonText, setSaveButtonText] = useState("Save changes")
  const {start} = useTimeout(() => setSaveButtonText("Save changes"), 1000)
  const [performingCalculations, setPerformingCalculations] = useState(false)
  const [losses, setLosses] = useState([])
  // copy for user changes
  const [tempLosses, setTempLosses] = useState([])
  const [error, setError] = useState("")
  const [tempTotalLosses, setTempTotalLosses] = useState(0)
  const [lossChanges, setLossChanges] = useState({})
  const [selectedNFTRemovals, setSelectedNFTRemovals] = useState([])
  // change when want to run estimation again - used to bust cache
  const [calcTrigger, setCalcTrigger] = useState(false)
  const canSaveChanges = Object.keys(lossChanges).length > 0 || selectedNFTRemovals.length > 0

  useEffect(() => {
    // don't have if opened cause not affecting other components
    let total = 0
    for (const lossTuple of tempLosses) {
      total += lossTuple[1][1] * lossTuple[2] || 0
    }
    setTempTotalLosses(total.toFixed(2))
  }, [tempLosses])

  useEffect(() => {
    if (opened) {
      let total = 0
      for (const lossTuple of losses) {
        total += lossTuple[1][1] * lossTuple[2] || 0
      }
      setEstimatedTokens(new Set(Object.keys(selectedNFTs)))
      setEstimatedLosses(total.toFixed(2))
    }
  }, [losses]) // eslint-disable-line react-hooks/exhaustive-deps

  const getEstimates = async (_losses, keys, addresses) => {
    const estimatedTokens = await getLossEstimates(account, keys, addresses)

    const allLosses = {...estimatedTokens, ..._losses}
    const sortedLosses = sortLosses(selectedNFTs, allLosses)
    const copiedLosses = [...sortedLosses]
    setLosses(sortedLosses)
    setTempLosses(copiedLosses)
    setPerformingCalculations(false)
  }
  useEffect(() => {
    if (opened) {
      const tokenLosses = {}
      const tokensToBeEstimated = {...selectedNFTs}
      setPerformingCalculations(true)

      // pull already calculated tokens from cache
      for (const key in selectedNFTs) {
        if (window.localStorage.getItem(key)) {
          tokenLosses[key] = Number(localStorage.getItem(key))
          delete tokensToBeEstimated[key]
        }
      }

      // calculate remaining tokens
      const nfts = Object.values(tokensToBeEstimated).map(nft => nft[0])
      const keys = Object.keys(tokensToBeEstimated)
      const addresses = getUniqueContractAddressesFromNFTs(nfts)
      getEstimates(tokenLosses, new Set(keys), addresses).catch(e => {
        console.error(e)
        setError("There was an error calculating your losses.")
        setPerformingCalculations(false)
      })
    }
  }, [opened, calcTrigger]) // eslint-disable-line react-hooks/exhaustive-deps

  const removeSelectedNFT = (key, i) => {
    setSelectedNFTRemovals([...selectedNFTRemovals, key])
    const copied = [...tempLosses]
    copied.splice(i, 1)
    setTempLosses(copied)
  }

  const bustCacheAndRerun = () => {
    window.localStorage.clear()
    setCalcTrigger(!calcTrigger)
  }

  const saveChanges = () => {
    const copied = [...tempLosses]
    setLosses(copied)
    for (const key in lossChanges) {
      window.localStorage.setItem(key, lossChanges[key])
    }

    const copiedSelectedNFTs = {...selectedNFTs}
    for (const removedNFT of selectedNFTRemovals) {
      delete copiedSelectedNFTs[removedNFT]
    }

    setLossChanges({})
    setSelectedNFTRemovals([])
    setSelectedNFTs(copiedSelectedNFTs)

    setSaveButtonText("Changes saved!")
    start()
  }

  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      withCloseButton={false}
      centered
      closeOnClickOutside={!performingCalculations}
      closeOnEscape={!performingCalculations}
      overlayColor={theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[2]}
      overlayOpacity={0.55}
      overlayBlur={3}
      size="75%"
    >
      {performingCalculations ? (
        <Stack my="xl" align="center">
          <Loader size="xl" />
          <Text color="dimmed">Calculating your losses...</Text>
        </Stack>
      ) : error ? (
        <Text color="red">{error}</Text>
      ) : (
        <Grid p={0} m={0}>
          <Grid.Col span={9}>
            <Table>
              <thead>
                <tr>
                  <th>Token</th>
                  <th>Quantity</th>
                  <th>Estimated Losses per Token (USD)</th>
                  <th width={150} style={{textAlign: "right"}}>
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {tempLosses.map((lossTuple, i) => {
                  return (
                    <tr key={lossTuple[0]}>
                      <td>
                        <Group>
                          {getMediaComponent(lossTuple[1][0], 100)}
                          {lossTuple[1][0].title}
                        </Group>
                      </td>
                      <td>{lossTuple[1][1]}</td>
                      <td>
                        <Group>
                          <NumberInput
                            defaultValue={lossTuple[2]}
                            precision={2}
                            min={0.01}
                            hideControls
                            onChange={val => {
                              const copiedLosses = [...tempLosses]
                              const copiedChanges = {...lossChanges}
                              const originalVal = Number(window.localStorage.getItem(lossTuple[0]))
                              if (val === originalVal) {
                                delete copiedChanges[lossTuple[0]]
                              } else {
                                copiedChanges[lossTuple[0]] = val
                              }
                              setLossChanges(copiedChanges)
                              copiedLosses[i][2] = val
                              setTempLosses(copiedLosses)
                            }}
                            sx={{width: "60%"}}
                          />
                          {lossTuple[2] === 0 && (
                            <Tooltip
                              label="This token is estimated to harvest no losses. If you junk this token, you may gain a profit and defeat the purpose of loss-harvesting. Consider removing this token or updating the loss estimate if you believe it to be incorrect."
                              multiline
                              width="25%"
                            >
                              <ThemeIcon variant="default" sx={{borderWidth: 0}}>
                                <IconAlertCircle color="red" />
                              </ThemeIcon>
                            </Tooltip>
                          )}
                          {lossTuple[1][0].tokenType === "ERC1155" && (
                            <Tooltip
                              label="We currently don't support estimations for ERC1155. You can still fill in loss info with your own knowledge."
                              multiline
                              width="25%"
                            >
                              <ThemeIcon variant="default" sx={{borderWidth: 0}}>
                                <IconAlertTriangle color="orange" />
                              </ThemeIcon>
                            </Tooltip>
                          )}
                          {/** TODO: separate null and undefined case (null = USD conversion couldn't be found) */}
                          {(lossTuple[2] === null || lossTuple[2] === undefined) &&
                            lossTuple[1][0].tokenType === "ERC721" && (
                              <Tooltip
                                label="We couldn't estimate the loss of this token. Please fill it in to the best of your knowledge if possible."
                                multiline
                                width="25%"
                              >
                                <ThemeIcon variant="default" sx={{borderWidth: 0}}>
                                  <IconAlertTriangle color="orange" />
                                </ThemeIcon>
                              </Tooltip>
                            )}
                        </Group>
                      </td>
                      <td>
                        <Group position="right">
                          <Text>{lossTuple[1][1] * lossTuple[2] || 0}</Text>
                          <CloseButton
                            sx={{marginTop: "1px"}}
                            size="md"
                            onClick={() => removeSelectedNFT(lossTuple[0], i)}
                          />
                        </Group>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Grid.Col>
          <Grid.Col span={3}>
            <Stack sx={{height: "100%"}} align="center" justify="flex-end" px="xl">
              <Group position="apart" sx={{width: "100%"}}>
                <Text weight={600}>Total Losses:</Text>
                <Text weight={500}>{tempTotalLosses}</Text>
              </Group>
              <Button sx={{width: "100%"}} disabled={!canSaveChanges} onClick={saveChanges}>
                {saveButtonText}
              </Button>
              <Tooltip
                label="To save bandwidth, we cache these estimates per token. Please only refresh if you've transferred one of these tokens since you last used this feature."
                width="25%"
                multiline
              >
                <Button variant="outline" sx={{width: "100%"}} onClick={bustCacheAndRerun}>
                  Refresh
                </Button>
              </Tooltip>
            </Stack>
          </Grid.Col>
        </Grid>
      )}
    </Modal>
  )
}
