import React from "react"
import {Title, Stack} from "@mantine/core"
import {shortenAddress, useLookupAddress, useEthers} from "@usedapp/core"
import AccountBar from "./AccountBar"

export default function AccountHeader() {
  const {account} = useEthers()
  const {ens} = useLookupAddress(account)

  return (
    <Stack align="center">
      <Title>Hey there, {ens ?? shortenAddress(account)}</Title>
      <AccountBar />
    </Stack>
  )
}
