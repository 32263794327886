import React, {useState} from "react"
import {Anchor, Group, Text, Tooltip} from "@mantine/core"
import SavingsEstimateModal from "./SavingsEstimateModal"

/**
 * Component for display what the estimated loss value is in the summary. It can exist in 3 states:
 * 1. Not estimated yet (it will be clickable text that triggers estimates)
 * 2. Estimated and up to date
 * 3. Estimate and outdated - more NFTs have been selected since updating, and this will be reflected by an orange indicator
 */
export default function EstimatedLoss({
  selectedNFTs,
  size,
  estimatedLosses,
  setEstimatedLosses,
  setEstimatedTokens,
  setSelectedNFTs
}) {
  const [modalOpened, setModalOpened] = useState(false)

  const selectedNFTKeys = new Set(Object.keys(selectedNFTs))
  const anchor = (text, color) => (
    <Anchor
      size={size}
      component="button"
      type="button"
      onClick={() => setModalOpened(true)}
      style={{marginLeft: "auto"}}
      color={color}
    >
      {text}
    </Anchor>
  )

  const estimatedLossComponent =
    estimatedLosses === null ? (
      selectedNFTKeys.size === 0 ? (
        <Text size={size} color="dimmed">
          Click to estimate
        </Text>
      ) : (
        anchor("Click to estimate")
      )
    ) : (
      <>
        {anchor("Edit", "orange")}
        <Text size={size}>{`${estimatedLosses} USD`}</Text>
      </>
    )

  return (
    <>
      <SavingsEstimateModal
        opened={modalOpened}
        setOpened={setModalOpened}
        selectedNFTs={selectedNFTs}
        setSelectedNFTs={setSelectedNFTs}
        setEstimatedTokens={setEstimatedTokens}
        setEstimatedLosses={setEstimatedLosses}
      />
      <Group position="apart" sx={{width: "100%"}}>
        <Tooltip
          label="We'll do our best to figure out the losses you're harvesting by junking these NFTs in USD! The process may take some time, so please wait a bit after kicking off the process."
          width={260}
          multiline
        >
          <Text size={size} weight={500}>
            Est. Losses:
          </Text>
        </Tooltip>
        {estimatedLossComponent}
      </Group>
    </>
  )
}
