import {Button, Stack, Title} from "@mantine/core"
import React, {useState} from "react"
import {Link} from "react-router-dom"
import EstimatedLoss from "../loss-estimates/EstimatedLoss"
import SelectedNFTsScroll from "./SelectedNFTsScroll"

export default function SelectedNFTs({
  nftData,
  selectedNFTs,
  setSelectedNFTs,
  existingEstimatedLosses,
  existingEstimatedTokens
}) {
  const [estimatedTokens, setEstimatedTokens] = useState(existingEstimatedTokens || new Set())
  const [estimatedLosses, setEstimatedLosses] = useState(existingEstimatedLosses)

  const nfts = Object.values(selectedNFTs)
  const totalItems = nfts.reduce((acc, nftDataWithCount) => acc + Number(nftDataWithCount[1]), 0)
  return (
    <>
      <Stack sx={{border: 0}} p="xs">
        <Title order={2}>
          {totalItems} Item{totalItems === 1 ? "" : "s"}
        </Title>

        <SelectedNFTsScroll nfts={nfts} textSize="sm" />
        <EstimatedLoss
          selectedNFTs={selectedNFTs}
          estimatedLosses={estimatedLosses}
          setEstimatedLosses={setEstimatedLosses}
          setEstimatedTokens={setEstimatedTokens}
          setSelectedNFTs={setSelectedNFTs}
          size="sm"
        />

        <Button
          disabled={nfts.length === 0}
          sx={{width: "100%"}}
          mt="lg"
          component={Link}
          to="/confirmation"
          state={{nftData, selectedNFTs, estimatedLosses, estimatedTokens}}
        >
          Continue
        </Button>
      </Stack>
    </>
  )
}
