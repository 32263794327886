import React from "react"
import {Link} from "react-router-dom"
import {Container, Stack, Title, Text} from "@mantine/core"
import BlogHeading from "../../components/blog/BlogHeading"
import {BLOG_TITLE_DO_I_NEED_TO_PAY} from "../../constants"
import {Helmet} from "react-helmet"

export default function DoINeedToPayTaxes() {
  return (
    <>
      <Helmet>
        <title>Do I need to pay taxes on NFTs? 2022 Guide | NFT Tax Saver</title>
        <meta
          name="description"
          content="Are you wondering whether you need to pay taxes on NFTs? What types of NFT transactions are taxed? Read our 2022 guide."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Container>
        <Stack>
          <BlogHeading
            title={BLOG_TITLE_DO_I_NEED_TO_PAY}
            author={{name: "The NFT Tax Saver Team"}}
            date={"October 20, 2022"}
          />
          <Text>
            If you’ve purchased or sold NFTs this year, you may be wondering whether there are tax
            implications. In short, yes — you will likely need to pay taxes on your NFT activity, or
            at least do a few calculations to make sure that you don’t owe any. In this guide, we’ll
            explain everything you need to know about NFT taxes.
          </Text>
          <Text>
            Note: this article should not be construed as official tax or legal advice. Always
            consult your own accountant or lawyer.
          </Text>
          <Title order={2}>What is an NFT?</Title>
          <Text>
            NFT stands for “non-fungible token.” NFTs are a specific type of token on blockchains
            such as Ethereum, Solana, and Avalanche. NFTs are notable in that each token can contain
            unique metadata, including images. This technical difference is why NFTs are often
            described as “tokens with JPEGs,” but in reality NFTs can contain any sort of metadata,
            including text, music, and more.
          </Text>
          <Text>
            NFTs exist in contrast to “fungible" tokens, like BTC, ETH, SOL, AVAX, DOGE, and SHIB.
            When it comes to fungible tokens, every single token is exactly identical to the other.
            Alice’s ETH is the same as Bob’s ETH, which is no different from Charlie’s ETH. With
            NFTs, however, Alice’s Bored Ape will be different from Bob’s Bored Ape because they
            contain different metadata — maybe Alice’s has the “laser eyes” property, while Bob’s
            does not.
          </Text>
          <Text>
            Although NFTs existed in 2017 (the first popular NFT collection, “Cryptokitties” was
            launched on Dec 12, 2017), they did not hit the mainstream until 2021 when collections
            such as Cryptopunks, Bored Ape Yacht Club, and Hashmasks surged in price.
          </Text>
          <Title order={2}>What can people do with NFTs?</Title>
          <Text>
            NFTs enable ownership of unique digital items, powered by a transparent, public
            blockchain ledger. Due to this technological advancement, in theory, NFTs have the
            potential to transform several industries. For example, with NFTs, gamers can
            potentially own the items they earn while playing games and even bring them to other
            games. The NFT industry is in its nascence, and many expect new use cases to emerge over
            the coming years.
          </Text>
          <Text>
            As of 2022, however, the main use case of NFTs is still speculation. The reality of the
            situation is that most NFTs are purchased to be sold, similar to other assets like art
            and collectibles.
          </Text>
          <Title order={2}>Are NFTs taxed?</Title>
          <Text>
            Yes. Investors are required to pay taxes on NFT trades that occurred during the past
            calendar year. Although the IRS has not provided clear guidance on how NFTs are treated
            in the tax code, NFTs are usually taxed as property. Currently, cryptocurrencies in
            general are commonly taxed as property.
          </Text>
          <Text>
            Contrary to what some may believe, NFTs are not tax free. NFTs are taxed as capital
            gains. If you choose to avoid paying taxes on your NFTs, the IRS will not be
            sympathetic. While they will likely understand if you make a mistake here and there, in
            general, they expect every taxpayer to make a good-faith effort to pay their taxes. If
            you don’t pay taxes or blatantly fail to follow the rules, you could get charged with
            tax evasion.
          </Text>
          <Text>
            One common misconception among NFT beginners is that the IRS will not be able to track
            your trades and won’t be able to determine how much you owe in taxes. On the contrary,
            you probably purchased your crypto on exchanges like Coinbase, Binance, FTX, or Kucoin,
            which are legally required to ask you for your name, address, and other identifying
            information. Once you transfer your crypto to a defi wallet and proceed to purchase NFTs
            on marketplaces, all of that is tracked on the blockchain – publicly, transparently, and
            permanently. Even if you think the IRS is not currently resourced to track down
            individuals who owe taxes via blockchain trades, they probably will be one day. It is
            seriously not a good idea to avoid paying taxes.
          </Text>
          <Title order={2}>How are NFTs taxed?</Title>
          <Text>
            NFTs are taxed as property, which means they are taxed based on capital gains. Capital
            gains are calculated based on your profit or loss from buying and selling NFTs. In
            general, the exact amount you’ll owe in NFT taxes is a function of your total realized
            profit, income, tax bracket, geography, and other tax-related factors.
          </Text>
          <Text>
            <Link to="/blog/how-are-nfts-taxed">
              Read our dedicated guide on how NFTs are taxed.
            </Link>
          </Text>
          <Title order={2}>What types of NFT transactions result in taxes?</Title>
          <Text>
            Below we’ll describe the various NFT transactions you may have performed and explain
            whether they trigger a taxable event.
          </Text>
          <Title order={3}>Buying an NFT Taxes</Title>
          <Text>
            Buying an NFT on its own will not trigger a tax event. When you buy an NFT, you have
            increased your “cost-basis,” but you have not actually realized a profit or loss — you
            would need to sell or exchange your NFT to realize a profit or loss. Even if your NFT’s
            value increases or decreases after you purchase it, if you do not sell it, you do not
            incur a taxable event.
          </Text>
          <Title order={5} italic>
            Buying an NFT Taxes Example:
          </Title>
          <Text>
            Alice purchases an NFT for $2000. She does not owe taxes, even if the NFT’s value
            increases to $4000 or decreases to $1000 based on floor value. In those cases, Alice has
            an “unrealized” gain of $2000 or an unrealized loss of $1000. But until she actually
            sells that NFT, she does not owe taxes on it.
          </Text>
          <Text>
            Warning: Most NFTs are purchased using cryptocurrency such as Ethereum or Solana. When
            you exchange a cryptocurrency for an NFT, you will create a taxable event on the
            cryptocurrency you sold to buy the NFT. In this case, you’re not being taxed on the NFT,
            but rather, your cryptocurrency. You’ll need to calculate the difference in what you
            just sold your crypto for and how much you originally bought it for.
          </Text>
          <Title order={5} italic>
            Selling Crypto to Buy an NFT Taxes Example:
          </Title>
          <Text>
            Alice purchases an NFT for $2000 using 1 ETH. In essence, she is selling 1 ETH to buy
            the NFT. When she originally bought the 1 ETH, however, it was only worth $500, meaning
            she realized a gain of $1500 on that Ethereum sale. This gain of $1500 is subject to
            tax. To be clear, she’s being taxed on the ETH sale, not the NFT purchase.
          </Text>
          <Title order={3}>Selling an NFT Taxes</Title>
          <Text>
            Selling an NFT will usually trigger a taxable event. When you sell an NFT, you are
            “realizing” either a gain or loss. The IRS will look at the difference between the price
            you acquired NFT for (the “cost-basis”) and how much you sold it for — this is called
            the “realized gain” or “realized loss.”
          </Text>
          <Text>
            Ultimately, NFTs are taxed based on the total realized gain or realized loss you have,
            so selling an NFT will create tax implications for you.
          </Text>
          <Title order={5} italic>
            Selling an NFT Taxes Example (Gains):
          </Title>
          <Text>
            Alice sells an NFT for $5000. She originally bought it for $2000. This means her
            realized gain is $3000. She owes taxes on that $3000.
          </Text>

          <Title order={5} italic>
            Selling an NFT Taxes Example (Losses):
          </Title>
          <Text>
            Bob sells an NFT for $500. Unfortunately, he originally bought it for $2000. This means
            his realized loss is $1500. The IRS allows you to use your losses to decrease your
            overall tax bill.*
          </Text>
          <Text size="sm" italic>
            *This technique is known as tax loss harvesting, and NFT traders can take advantage of
            it too.{" "}
            <Link to="/blog/how-to-tax-loss-harvest-nfts">
              Learn more about tax loss harvesting here.
            </Link>
            **
          </Text>
          <Text size="sm" italic>
            ** Take advantage of our <Link to="/">NFT Tax Loss Harvester</Link> which allows you to
            realize a loss on any NFT, even if there is no one else to buy your NFT.
          </Text>
          <Title order={3}>Swapping one NFT for another NFT</Title>
          <Text>
            When you swap one NFT for another NFT, you are effectively selling NFT A to buy NFT B.
            As a result, you will owe taxes on the realized gain or loss between your original
            purchase price of NFT A and the value NFT A just now when you sold it. You will not owe
            taxes on NFT B (since you only bought it; see “Buying an NFT Taxes” above), but if you
            choose to sell or exchange NFT B later, you will owe taxes on the difference in value
            then.
          </Text>
          <Title order={3}>NFT Flipping Taxes</Title>
          <Text>
            Since “NFT flipping” refers to buying and selling NFTs, yes, they are considered taxable
            events. You will owe taxes on the realized gain or loss between the original purchase
            price and the sale price. Scroll up to “Buying an NFT Taxes” and “Selling an NFT Taxes”
            to learn more.
          </Text>
          <Title order={3}>NFT Trading Taxes</Title>
          <Text>
            NFT trading refers to buying and selling NFTs, so you will owe taxes on the realized
            gain or loss between the original purchase price and sale price. Again, we recommend
            scrolling up to “Buying an NFT Taxes” and “Selling an NFT Taxes” to see our tax
            explanations.
          </Text>
          <Title order={3}>Burn NFT Taxes</Title>
          <Text>
            When you “burn” an NFT, you are actually just sending it to a dead wallet address that
            nobody can access any more. You’re not actually “destroying” the NFT; you’re just moving
            it somewhere inaccessible. In the eyes of the IRS, this is not considered a “sale” so
            you will not incur a taxable event.
          </Text>
          <Text>
            Some people try to burn NFTs as a way to realize losses on an NFT, thinking it will
            reduce their realized profit and therefore tax obligation. Unfortunately, in the eyes of
            the IRS, burning is not a valid method of realizing losses on your NFTs because it is
            not a transaction involving two parties looking to buy and sell in good faith. Do not
            burn NFTs as a way to reduce your losses — this could be seen as tax evasion.
          </Text>
          <Text>
            What can you do if you want to realize a loss on a NFT, but you don’t have anyone to buy
            your NFT from you? <Link to="/">Use NFT Tax Saver.</Link> We’ll buy any NFT off of you
            for 1 gwei and you’ll save on taxes.
          </Text>
          <Title order={3}>NFT Staking</Title>
          <Text>
            When you stake an NFT, you still own the underlying NFT; you are just “staking” it into
            a smart contract. Because ownership of the NFT is not changing and no sale is occurring,
            you will not incur a taxable event when staking or unstaking your NFT.
          </Text>
          <Text>
            If the reason you are staking your NFT is that you are receiving another token as a
            reward, then you may owe taxes on those reward tokens. They may be considered income,
            and you would be taxed at the value of the tokens as the moment you received them. But
            again, you are not going to be taxed specifically on the NFT you staked itself.
          </Text>
          <Title order={3}>NFT Gift Tax</Title>
          <Text>
            If you were gifted an NFT, you will need to pay taxes on the full value of the NFT at
            the time it was received. So, if Alice gifts Bob an NFT worth $30,000, then Bob will
            need to pay taxes on the NFT as though it were an addition to his income. You should
            research the exact guidance on how much a gift needs to be worth before it is taxed; as
            of 2022, the limit appears to be $16,000. So if your gift is worth less than that
            amount, the IRS will not get involved. Again, this is not tax advice; always check with
            your own accountant first.
          </Text>
          <Title order={2}>
            How much will I pay in NFT taxes? What are the tax implications of NFTs?
          </Title>
          <Text>
            Your total NFT tax obligation is calculated based on your income, your tax bracket,
            total realized profit or less, and geography. NFT tax consequences are unique to each
            individual but understanding these NFT tax considerations will help you pay your taxes
            correctly.
          </Text>
          <Text>
            Check out our dedicated guide on calculating how much tax you need to pay on your NFTs.
          </Text>
          <Title order={2}>Do I need to pay taxes on NFTs from Opensea or MagicEden?</Title>
          <Text>
            Yes, you need to pay taxes on NFTs you purchased and sold on Opensea or MagicEden. The
            tax rules for NFTs purchased and sold on these marketplaces is no different than NFTs
            traded elsewhere.
          </Text>
          <Title order={2}>Can NFTs be used for tax evasion?</Title>
          <Text>
            NFTs should not be used for tax evasion any more than any other assets should be used
            for avoiding taxes. Dodging taxes owed on NFTs will result in serious consequences
            similar to if you avoided taxes using any other asset. We strongly recommend that you
            pay your taxes on NFTs (and assets in general) properly.
          </Text>
          <Title order={2}>Are NFTs tax exempt?</Title>
          <Text>
            No, NFTs are not tax-exempt. You need to pay taxes on NFT transactions where you realize
            a gain or loss.
          </Text>
          <Title order={2}>Is it possible to donate an NFT to reduce my taxes?</Title>
          <Text>
            NFT tax donations are uncommon but theoretically possible. Nonprofit organizations
            receive 100% of donations tax-free, and donors pay no capital gains taxes. The full
            value of the contribution is preserved because nonprofit organizations are free from
            paying capital gains tax when they sell assets.
          </Text>
          <Text>
            However, there are a few challenges associated with NFT tax donations. First, it is hard
            to find certified charities that will accept NFT donations. Second, you need to get the
            value of the NFT appraised before you can officially donate it.
          </Text>
          <Text>
            Rather than donating your NFT, consider using NFT Tax Saver. We’ll buy your NFT from you
            for 1 gwei and help you realize a loss that will lower your tax obligation.{" "}
            <Link to="/">Learn more.</Link>
          </Text>
        </Stack>
      </Container>
    </>
  )
}
